@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

a,
button,
span,
input[type="checkbox"]+label,
input[type="radio"],
input[type="submit"],
select {
  cursor: pointer;
}

::selection {
  background: #f7b614;
}

/* turn it off completely */
select:active,
select:hover {
  outline: none;
}

/* make it yellow instead (with with same width and style) */
select:active,
select:hover {
  outline-color: #f7b614;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* turn off up and down button in number fields
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 768px) {

  /* Hide scrollbar for Chrome, Safari and Opera */
  body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

}
.ml-loader {
  position: relative;
  width: 70px;
  height: 70px;
}

.ml-loader div {
  -webkit-transform-origin: 32px 32px;
  transform-origin: 32px 32px;
  -webkit-animation: 1.2s opaque ease-in-out infinite both;
  animation: 1.2s opaque ease-in-out infinite both;
}

.ml-loader div::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  width: 5px;
  height: 18px;
  border-radius: 10px;
  background-color: #808080;
}

.ml-loader div:nth-child(1) {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.ml-loader div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.ml-loader div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ml-loader div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.ml-loader div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ml-loader div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.ml-loader div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.ml-loader div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.ml-loader div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.ml-loader div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.ml-loader div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.ml-loader div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.ml-loader div:nth-child(13) {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@-webkit-keyframes opaque {
  0%,
  40%,
  100% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
}

@keyframes opaque {
  0%,
  40%,
  100% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
}
/*# sourceMappingURL=LoaderSmall.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.login-modal-container {
  position: fixed;
  z-index: 99;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-modal-container .login-modal {
  border: 0;
  border-radius: 25px;
  width: 600px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
}

.login-modal-container .login-modal .login-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #32343a;
  color: white;
  height: 50px;
  border-bottom: 3px solid #f7b614;
  border-radius: 20px 20px 0 0;
  margin-top: -2px;
}

.login-modal-container .login-modal .login-modal-header .close-modal {
  color: white;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.login-modal-container .login-modal .login-modal-header .close-modal:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.login-modal-container .login-modal .login-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #32343a;
  height: 50px;
  border-top: 3px solid #f7b614;
  border-radius: 0 0 20px 20px;
}

.login-modal-container .login-modal .login-modal-footer button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 15px;
  width: 100px;
  height: 70%;
  font-size: 15px;
  font-weight: 700;
  outline: 0;
}

.login-modal-container .login-modal .login-modal-footer button:hover {
  border: 2px solid #f7b614;
}

.login-modal-container .login-modal .login-modal-footer button:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.login-modal-content,
.login-modal-confirmation {
  display: flex;
  flex-direction: column;
  padding: .9rem 2rem;
  color: #32343a;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: space-evenly;
}

.login-modal-content p,
.login-modal-confirmation p {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.termsandcondition {
  width: 100%;
  height: 20rem;
}

.login-modal-terms {
  display: flex;
  align-items: center;
}

.login-modal-terms a {
  color: blue;
}

#terms {
  visibility: hidden;
}

.input-black-box {
  border: 2px solid #585858;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
  color: white;
  font-size: .7rem;
}

.input-black-box i {
  display: none;
}

input[type="checkbox"]:checked ~ .input-black-box {
  background-color: #585858;
}

input[type="checkbox"]:checked ~ .input-black-box i {
  display: flex;
}

.login-modal-content form {
  width: 100%;
}

@media screen and (max-width: 620px) {
  .login-modal {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    border-radius: 0;
  }
  .login-modal-terms {
    font-size: 3.2vmin;
  }
  .login-modal-content,
  .login-modal-confirmation {
    padding: .9rem .5rem;
  }
}
/*# sourceMappingURL=LoginModal.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.message-box-container {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-box-container .message-box-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 20rem;
  max-width: 31rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
}

.message-box-container .message-box-content .message-box-body {
  background-color: white;
  padding: 1.5rem 3rem;
  display: flex;
  flex-wrap: wrap;
  font-size: .9rem;
  color: #32343a;
  overflow-y: scroll;
  overflow-x: hidden;
}

.message-box-container .message-box-content .message-box-body .loader-container {
  margin: 0;
}

.message-box-container .message-box-content .btn-style-white {
  border: 0;
  background: white;
  text-align: center;
  padding: 7px 30px;
  outline: none;
  color: #32343a;
  border: 2px solid white;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0px 6px 5px -2px #525252;
}

.message-box-container .message-box-content .btn-style-white:hover {
  border: 2px solid #32343a;
}

.message-box-container .message-box-content .message-box-footer {
  position: relative;
  padding: .6rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  margin-bottom: -1px;
}

.message-box-container .message-box-content .message-box-footer button {
  z-index: 9;
}

.message-box-container .message-box-content .message-box-footer::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7b614;
  border-radius: 50% 50% 0 0 / 50% 50% 0 0;
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  z-index: 9;
}

.message-box-container .message-box-content .message-box-header {
  position: relative;
  padding: .5rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  color: #32343a;
  font-size: 1.3rem;
  font-weight: 700;
  min-height: 3.3rem;
}

.message-box-container .message-box-content .message-box-header p {
  z-index: 9;
}

.message-box-container .message-box-content .message-box-header i {
  color: white;
  z-index: 9;
  position: absolute;
  right: 5%;
  font-size: 2rem;
  cursor: pointer;
}

.message-box-container .message-box-content .message-box-header i:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.message-box-container .message-box-content .message-box-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7b614;
  border-radius: 0 0 50% 50%/0 0 50% 50%;
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  z-index: 9;
}
/*# sourceMappingURL=MessageBox.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 20rem;
  height: 100%;
  min-height: 20rem;
  background-color: transparent;
  margin: 1rem;
}

.loader-container .loader-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
}

.loader-container .loader-header .logo-icon {
  height: 20vmin;
  pointer-events: none;
  position: absolute;
}

.loader-container .loader-header .triple-spinner {
  display: block;
  position: relative;
  width: 23vmin;
  height: 23vmin;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #ff7300;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-container .loader-header .triple-spinner::before,
.loader-container .loader-header .triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}

.loader-container .loader-header .triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #FF9800;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}

.loader-container .loader-header .triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #FFC107;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 550px) {
  .loader-container .loader-header .logo-icon {
    height: 25vmin;
  }
  .loader-container .loader-header .triple-spinner {
    width: 28vmin;
    height: 28vmin;
  }
}

@media screen and (max-width: 500px) {
  .loader-container .loader-header .logo-icon {
    height: 25vmin;
  }
  .loader-container .loader-header .triple-spinner {
    width: 30vmin;
    height: 30vmin;
  }
}

@media screen and (max-width: 500px) {
  .loader-container .loader-header .logo-icon {
    height: 35vmin;
  }
  .loader-container .loader-header .triple-spinner {
    width: 40vmin;
    height: 40vmin;
  }
}

@media screen and (max-width: 330px) {
  .loader-container .loader-header .logo-icon {
    height: 35vmin;
  }
  .loader-container .loader-header .triple-spinner {
    width: 45vmin;
    height: 45vmin;
  }
}
/*# sourceMappingURL=Loader.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
}

a span {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

nav {
  max-width: 2580px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #32343a;
  padding: 1rem 4rem;
  border-bottom: 3px solid #f7b614;
  margin-left: auto;
  margin-right: auto;
}

nav .navigation-list-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

nav .loged-in-user-my-profile {
  display: none;
}

nav .logged-in-user-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #f7b614;
}

nav .logged-in-user {
  display: flex;
  align-items: center;
}

nav .my-profile-icon {
  display: none;
}

nav .log-button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  height: 2.1rem;
  width: 5.6rem;
  border-radius: 15px;
  outline: none;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav .log-button:hover {
  border-color: #f7b614;
}

nav .log-button i {
  margin-right: 3rem;
  display: none;
}

nav .loged-in-user-icon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: .2rem;
}

nav .loged-in-user-icon img {
  width: 2rem;
  height: auto;
}

nav .loged-in-user-name {
  cursor: pointer;
}

nav .deMango-logo {
  width: 15%;
  height: 10%;
  -ms-grid-column-align: start;
      justify-self: start;
  cursor: pointer;
}

nav ul {
  margin: auto;
}

nav ul li {
  display: inline-block;
  margin: 0 15px;
}

nav ul li .navbar-icon {
  display: none;
  margin-right: 3rem;
}

nav ul li .delivery {
  display: none;
}

nav ul li a {
  color: white;
  font-size: 17px;
}

nav ul li a:hover {
  color: #f7b614;
  transition: .3s;
}

nav ul button:hover {
  color: #f7b614;
  border-color: #f7b614;
}

nav .checkbtn {
  font-size: 30px;
  color: #f7b614;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}

nav #check {
  display: none;
}

@media screen and (min-width: 3000px) {
  nav .log-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1rem;
    font-size: 1.5vmin;
    font-weight: 300;
    border-radius: 2rem;
  }
  nav .loged-in-user-icon img {
    width: 5vmin;
  }
  nav .navigation-list-container {
    font-size: 2vmin;
    font-weight: 300;
  }
  nav ul li a {
    font-size: 2vmin;
    font-weight: 300;
  }
}

@media screen and (min-width: 1600px) and (max-width: 3000px) {
  nav .deMango-logo {
    width: 10%;
  }
  nav .loged-in-user-icon img {
    width: 4vmin;
  }
  nav .log-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .5rem 1rem;
    font-size: 2vmin;
    font-weight: 300;
    border-radius: 2rem;
  }
  nav .navigation-list-container {
    font-size: 2vmin;
    font-weight: 300;
  }
  nav ul li a {
    font-size: 2vmin;
    font-weight: 300;
  }
}

@media screen and (min-width: 768px) and (max-width: 850px) {
  nav {
    padding-left: 15px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  nav {
    position: fixed;
    padding-left: 0;
    padding-right: 0;
  }
  nav .navigation-list-container {
    position: fixed;
    display: flex;
    width: 100%;
    height: calc(100vh - 5.7rem);
    top: 5.7rem;
    left: -100%;
    transition: all .5s;
    background: linear-gradient(to right, white 0%, white 70%, rgba(50, 52, 58, 0.9) 70%, rgba(50, 52, 58, 0.9) 100%);
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 3rem;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  nav .navigation-list-container::-webkit-scrollbar {
    display: none;
  }
  nav .logged-in-user-container {
    width: 70%;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 3rem;
  }
  nav .logged-in-user-container img {
    display: none;
  }
  nav .logged-in-user-container .navbar-loader {
    margin-left: auto;
    margin-right: auto;
  }
  nav .gold-user {
    background: none;
  }
  nav .gold-user p {
    color: #808080;
  }
  nav .loged-in-user-my-profile {
    display: block;
  }
  nav .loged-in-user-name {
    display: none;
  }
  nav .logged-in-user-container {
    align-items: flex-start;
    color: #808080;
  }
  nav .my-profile-icon {
    display: flex;
    margin-right: 2rem;
    margin-left: 16px;
  }
  nav .loged-in-user-icon {
    display: flex;
    align-items: center;
  }
  nav .loged-in-user-icon:hover {
    background: none;
    color: #f7b614;
  }
  nav .loged-in-user-my-profile {
    color: #808080;
    cursor: pointer;
    margin-left: 15px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  nav .loged-in-user-my-profile:hover {
    background: none;
    color: #f7b614;
  }
  nav .log-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: 0;
    font-size: 3rem;
    padding: 1rem 0;
    color: #808080;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
  }
  nav .log-button:hover {
    background: none;
    color: #f7b614;
  }
  nav .log-button i {
    display: block;
  }
  nav img {
    margin-right: 0;
    margin-left: 4rem;
  }
  nav .checkbtn {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }
  nav ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    width: 70%;
    margin-left: 0;
  }
  nav ul li {
    display: flex;
    position: relative;
    padding: 1rem 0;
  }
  nav ul li .navbar-icon {
    display: block;
  }
  nav ul li .kolorado-delivery {
    display: none;
  }
  nav ul li .delivery {
    display: flex;
    margin-left: -10px;
  }
  nav ul li a {
    font-size: 3rem;
    color: #808080;
    display: flex;
  }
  nav ul li a:hover {
    background: none;
    color: #f7b614;
  }
  nav #check:checked .app-container {
    overflow: hidden;
  }
  nav #check:checked ~ .navigation-list-container {
    left: 0;
    z-index: 99;
  }
  nav .deMango-logo {
    width: 50%;
    max-width: 200px;
    height: 3.5rem;
  }
}

@media screen and (max-width: 500px) {
  nav .deMango-logo {
    margin-left: 2rem;
  }
  nav .logged-in-user-container,
  nav .log-button {
    font-size: 2.5rem;
    color: #808080;
  }
  nav ul li a {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 450px) {
  nav .navigation-list-container {
    background: linear-gradient(to right, white 0%, white 80%, rgba(50, 52, 58, 0.9) 80%, rgba(50, 52, 58, 0.9) 100%);
  }
  nav .logged-in-user-container {
    width: 80%;
  }
  nav .logged-in-user-container,
  nav .log-button {
    font-size: 2rem;
    color: #808080;
  }
  nav ul li a {
    font-size: 2rem;
  }
  nav ul {
    width: 80%;
  }
}

@media screen and (max-width: 392px) {
  nav .navigation-list-container {
    height: calc(100vh - 5.58rem);
    top: 5.58rem;
  }
}

@media screen and (max-width: 378px) {
  nav .navigation-list-container {
    height: calc(100vh - 5.5rem);
    top: 5.5rem;
  }
  nav .deMango-logo {
    height: 3.2rem;
  }
}

@media screen and (max-width: 371px) {
  nav .navigation-list-container {
    height: calc(100vh - 5.4rem);
    top: 5.4rem;
  }
}

@media screen and (max-width: 355px) {
  nav {
    padding-left: 0;
    padding-right: 0;
  }
  nav .navigation-list-container {
    height: calc(100vh - 5.3rem);
    top: 5.3rem;
  }
  nav .deMango-logo {
    margin-right: 0;
  }
  nav img {
    width: 50%;
    max-width: 200px;
    height: auto;
  }
}

@media screen and (max-width: 349px) {
  nav .navigation-list-container {
    height: calc(100vh - 5.2rem);
    top: 5.2rem;
  }
  nav .deMango-logo {
    height: 2.8rem;
  }
}

@media screen and (max-width: 349px) {
  nav .navigation-list-container {
    height: calc(100vh - 5rem);
    top: 5rem;
  }
}

@media screen and (max-width: 315px) {
  nav .navigation-list-container {
    height: calc(100vh - 4.9rem);
    top: 4.9rem;
    background: linear-gradient(to right, white 0%, white 85%, rgba(50, 52, 58, 0.9) 85%, rgba(50, 52, 58, 0.9) 100%);
  }
  nav .logged-in-user-container {
    width: 90%;
  }
  nav ul {
    width: 90%;
  }
}
/*# sourceMappingURL=Header.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.demnago-baner {
  background-image: url(../../static/media/backgroundblack.3ac7811a.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 55%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
}

.demnago-baner .moto {
  font-style: italic;
  font-size: x-large;
  color: #32343a;
}

.demnago-baner .moto-h1 {
  -webkit-filter: drop-shadow(0px 0px 2px rgba(253, 253, 253, 0.75));
          filter: drop-shadow(0px 0px 2px rgba(253, 253, 253, 0.75));
}

.demnago-baner button {
  width: 150px;
  height: 50px;
  font-weight: 700;
  font-style: italic;
  color: #32343a;
  background-color: #f7b614;
  border-radius: 15px;
  border: none;
  text-align: center;
  transition: all 0.5s;
  margin: 5px;
  outline: 0;
}

.demnago-baner button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.demnago-baner button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.demnago-baner .button:hover span {
  padding-right: 25px;
}

.demnago-baner .button:hover span:after {
  opacity: 1;
  right: 0;
}

.demnago-baner .moto,
.demnago-baner .moto-button {
  padding-left: 10%;
}

@media screen and (max-width: 1450px) {
  .demnago-baner {
    background-size: 110%;
    background-position: 0 60%;
  }
}

@media screen and (max-width: 1150px) {
  .demnago-baner {
    background-size: 130%;
    background-position: 17% 70%;
  }
}

@media screen and (max-width: 900px) {
  .demnago-baner {
    background-size: 150%;
    background-position: 17% 70%;
  }
  .demnago-baner .moto-h1 {
    font-size: 40px;
  }
  .demnago-baner .moto,
  .demnago-baner .moto-button {
    padding-left: 5%;
  }
}

@media screen and (max-width: 768px) {
  .demnago-baner {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 725px) {
  .demnago-baner {
    height: 280px;
    background-size: 120%;
    background-position: 0% 79%;
  }
  .demnago-baner .moto .moto-h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 670px) {
  .demnago-baner {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .demnago-baner {
    height: 250px;
    background-size: 120%;
    background-position: 0% 79%;
  }
  .demnago-baner .moto {
    margin-bottom: 10px;
  }
  .demnago-baner .moto .moto-h1 {
    font-size: 28px;
  }
}

@media screen and (max-width: 499px) {
  .demnago-baner {
    height: 200px;
    background-size: 120%;
    background-position: 0% 79%;
  }
  .demnago-baner .moto {
    margin-bottom: 10px;
  }
  .demnago-baner .moto .moto-h1 {
    font-size: 20px;
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
  .demnago-baner {
    height: 200px;
    background-size: 140%;
    background-position: 17% 77%;
  }
  .demnago-baner .moto {
    margin-bottom: 10px;
  }
  .demnago-baner .moto .moto-h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 360px) {
  .demnago-baner {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 349px) {
  .demnago-baner {
    height: 180px;
    background-size: 140%;
    background-position: 17% 79%;
  }
  .demnago-baner .moto {
    margin-bottom: 10px;
  }
  .demnago-baner .moto .moto-h1 {
    font-size: 18px;
  }
}
/*# sourceMappingURL=PartnerMenu.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.delivery-modal-container {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.dialog-modal {
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  background-color: white;
  border-radius: .5rem;
}

.dialog-modal .input-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4%;
  position: relative;
}

.dialog-modal .input-container .delivery-price-info {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #585858;
  border-radius: 50%;
  margin-left: .5rem;
  margin-right: .5rem;
}

.dialog-modal .input-container .delivery-price-info i {
  color: #585858;
}

.dialog-modal .input-container .delivery-price-info:hover {
  border-color: #f7b614;
}

.dialog-modal .input-container .delivery-price-info:hover ~ .tooltip .no-need-for-sender {
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: ease-in-out .5s;
}

.dialog-modal .input-container .delivery-price-info:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.dialog-modal .input-container .tooltip {
  display: inline-block;
  border-bottom: 1px solid white;
  color: white;
  visibility: hidden;
  position: absolute;
  right: 0;
}

.dialog-modal .input-container .tooltip .no-need-for-sender {
  visibility: hidden;
  width: 180px;
  background-color: white;
  color: #32343a;
  text-align: center;
  border-radius: 6px;
  padding: 10px 5px;
  position: absolute;
  z-index: 52;
  top: -2rem;
  right: -1rem;
  transition: ease-in-out .5s;
  -webkit-transform: scale(0);
          transform: scale(0);
  border: 2px solid #f7b614;
}

.dialog-modal .input-container .tooltip .no-need-for-sender {
  content: "";
  position: absolute;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
}

.dialog-modal #no-need-for-sender {
  display: none;
}

.dialog-modal .sender-label {
  font-weight: 500;
  color: #32343a;
}

.dialog-modal .input-black-box {
  border: 2px solid #585858;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: white;
  font-size: .7rem;
}

.dialog-modal .input-black-box i {
  display: none;
}

.dialog-modal input[type="checkbox"]:checked ~ .input-black-box {
  background-color: #585858;
}

.dialog-modal input[type="checkbox"]:checked ~ .input-black-box i {
  display: flex;
}

.dialog-modal input {
  margin-top: 2%;
}

.dialog-modal .dialog-content {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dialog-modal .dialog-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  color: white;
  min-height: 60px;
  padding-bottom: .5rem;
  justify-content: flex-end;
}

.dialog-modal .dialog-header::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #32343a;
  border-radius: 0 0 50% 50% / 0 0 50% 50%;
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  border-bottom: 4px solid #f7b614;
}

.dialog-modal .dialog-header .close-modal {
  color: white;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  z-index: 2;
}

.dialog-modal .dialog-header .close-modal:hover {
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
}

.dialog-modal .dialog-header h2 {
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}

.dialog-modal .dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  height: 50px;
  position: relative;
}

.dialog-modal .dialog-footer::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #32343a;
  border-radius: 50% 50% 0 0 / 50% 50% 0 0;
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  padding: .3rem;
  border-top: 4px solid #f7b614;
}

.dialog-modal .dialog-footer div {
  position: absolute;
  bottom: 0;
}

.dialog-modal .dialog-footer button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 15px;
  width: 100px;
  height: 70%;
  font-size: 15px;
  font-weight: 700;
  outline: 0;
  z-index: 2;
}

.dialog-modal .dialog-footer button:hover {
  border: 2px solid #f7b614;
}

.dialog-modal .dialog-footer button:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.dialog-modal .dialog-footer .disabled {
  opacity: .3;
}

.dialog-modal .dialog-footer .disabled:hover {
  border: 2px solid white;
}

.modal-body-form {
  padding: 20px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
}

.modal-body-form .modal-body-form-combobox {
  margin-bottom: 1rem;
  color: #808080;
  font-size: 1.1rem;
}

.modal-body-form .modal-body-form-combobox label {
  margin-right: 1rem;
}

.modal-body-form .modal-body-form-combobox select {
  padding: .5rem;
  border: 2px solid #32343a;
  border-radius: 5px;
  background-color: white;
}

.modal-body-form .modal-body-form-combobox .combobox-button {
  margin-left: 1rem;
  padding: .3rem .5rem;
  background-color: white;
  border: 2px solid #32343a;
  border-radius: 5px;
  color: #32343a;
}

.modal-body-form .modal-body-form-combobox .combobox-button:hover {
  border: 2px solid #f7b614;
}

.modal-body-form .modal-body-form-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body-form .modal-body-form-message h3 {
  color: #32343a;
}

.modal-body-form h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #32343a;
}

.modal-body-form .modal-sender-form,
.modal-body-form .modal-receiver-form {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-body-form .modal-body-comment {
  height: 170px;
  display: flex;
  flex-direction: column;
}

.modal-body-form .modal-body-comment .text-area {
  border: 2px solid #32343a;
  border-radius: 3px;
  padding: 10px;
  outline-color: #f7b614;
  color: #32343a;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .dialog-modal .grecaptcha-badge {
    z-index: -1;
  }
  .dialog-modal .input-container .tooltip .no-need-for-sender {
    top: -2rem;
    right: 0rem;
  }
}

@media screen and (max-width: 600px) {
  .delivery-modal-container {
    /* Hide scrollbar for Chrome, Safari and Opera */
    /* Hide scrollbar for IE, Edge and Firefox */
  }
  .delivery-modal-container .dialog-modal {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 0;
  }
  .delivery-modal-container .dialog-modal .dialog-content {
    height: 100%;
  }
  .delivery-modal-container .dialog-modal .input-container .tooltip .no-need-for-sender {
    top: 2rem;
  }
  .delivery-modal-container .dialog-modal::-webkit-scrollbar {
    display: none;
  }
  .delivery-modal-container .dialog-modal {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .delivery-modal-container .dialog-footer,
  .delivery-modal-container .dialog-header {
    height: 14vmax;
  }
  .delivery-modal-container .dialog-header h2 {
    font-size: 4vmax;
  }
  .delivery-modal-container .dialog-header .close-modal {
    font-size: 1.5rem;
  }
  .delivery-modal-container .dialog-footer button {
    height: 6vmax;
  }
  .delivery-modal-container .modal-body-form {
    background-image: url(../../static/media/grayfillmango.66bf62e3.svg);
    background-repeat: no-repeat;
    background-size: 400%;
    background-position: 50% 70%;
  }
  .delivery-modal-container .modal-body-form .modal-body-form-combobox {
    margin-bottom: 2rem;
  }
  .delivery-modal-container .modal-body-form .modal-body-form-combobox select {
    width: 100%;
  }
  .delivery-modal-container .modal-body-form h2 {
    margin-bottom: 2vmin;
  }
  .delivery-modal-container .modal-body-form .modal-sender-form,
  .delivery-modal-container .modal-body-form .modal-receiver-form {
    height: 15rem;
    min-height: 12.5rem;
  }
  .delivery-modal-container .modal-body-form .modal-body-comment {
    height: 170px;
    display: flex;
  }
  .delivery-modal-container .modal-body-form .modal-body-comment .text-area {
    border: 2px solid #32343a;
    border-radius: 3px;
    padding: 10px;
    outline-color: #f7b614;
    color: #32343a;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 369px) {
  .dialog-modal .input-container .delivery-price-info {
    position: absolute;
    right: -1rem;
  }
}
/*# sourceMappingURL=DeliveryModal.css.map */
.error-meesage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-meesage h3 {
  color: #32343a;
}

.error-meesage img {
  width: 10rem;
  height: 10rem;
  margin-bottom: 1.5rem;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke: #f7b614;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 4% auto;
  box-shadow: inset 0px 0px 0px #f7b614;
  -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
          animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes scale {
  0%,
  100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}

@-webkit-keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px #f7b614;
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px #f7b614;
  }
}
/*# sourceMappingURL=Success.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.quick-pick-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.quick-pick-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.quick-pick-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 3%;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: white;
  margin-right: 0;
}

.quick-pick-container .quick-selection {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  height: 12rem;
}

.quick-pick-container .quick-selection::-webkit-scrollbar {
  display: none;
}

.quick-pick-container .go-left img,
.quick-pick-container .go-right img {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  transition: ease-in-out;
}

.quick-pick-container .go-left img:active,
.quick-pick-container .go-right img:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.quick-pick-container .quick-pick {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-width: 18.75rem;
  height: 6.25rem;
  background-color: #ececec;
  border-radius: 15px;
  color: #32343a;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  margin: 5px;
  cursor: pointer;
  margin-right: 3.5%;
  margin-left: 3.5%;
}

.quick-pick-container .quick-pick .quick-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7b614;
  width: 85px;
  height: 85%;
  border-radius: 150px;
}

.quick-pick-container .quick-pick .quick-icon img {
  width: 50px;
  height: auto;
}

.quick-pick-container .quick-pick .quick-title {
  font-size: 20px;
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quick-pick-container .quick-pick:hover {
  background-color: white;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 10px 10px 26px -6px #32343a;
}

@media screen and (max-width: 2450px) {
  .quick-pick-container .quick-pick {
    margin-right: 5.6%;
    margin-left: 5.6%;
  }
}

@media screen and (max-width: 1000px) {
  .quick-pick-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .quick-pick-container .go-left,
  .quick-pick-container .go-right {
    display: none;
  }
  .quick-pick-container .quick-selection {
    overflow-x: unset;
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .quick-pick-container .quick-pick {
    width: 350px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 820px) {
  .quick-pick-container .quick-pick {
    width: 300px;
  }
}

@media screen and (max-width: 735px) {
  .quick-pick-container .quick-pick {
    width: 300px;
  }
}

@media screen and (max-width: 662px) {
  .quick-pick-container .quick-pick {
    min-width: 200px;
    width: 250px;
  }
}

@media screen and (max-width: 550px) {
  .quick-pick-container .quick-pick {
    width: 100%;
  }
}
/*# sourceMappingURL=QuickPick.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.presentation-container {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
}

.presentation-container .image-presentation-container,
.presentation-container .info-presentation-container {
  width: 50%;
  height: 100%;
}

.presentation-container .info-presentation-container {
  position: relative;
  background-color: #32343a;
  display: flex;
  flex-direction: column;
}

.presentation-container .info-presentation-container .info-position {
  position: absolute;
  z-index: 1;
  top: 20%;
  left: 15%;
}

.presentation-container .info-presentation-container .info-position .icon-div {
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.presentation-container .info-presentation-container .info-position .icon-div img {
  width: 70px;
  height: auto;
}

.presentation-container .info-presentation-container .info-position .icon-div .icon-one,
.presentation-container .info-presentation-container .info-position .icon-div .icon-two,
.presentation-container .info-presentation-container .info-position .icon-div .icon-three,
.presentation-container .info-presentation-container .info-position .icon-div .icon-four {
  width: 20%;
}

.presentation-container .info-presentation-container .info-position .icon-div .icon-one p,
.presentation-container .info-presentation-container .info-position .icon-div .icon-two p,
.presentation-container .info-presentation-container .info-position .icon-div .icon-three p,
.presentation-container .info-presentation-container .info-position .icon-div .icon-four p {
  color: white;
  margin-top: 5px;
}

.presentation-container .info-presentation-container .info-position .head-one {
  color: white;
  font-size: 40px;
  font-weight: 800;
}

.presentation-container .info-presentation-container .info-position .head-two {
  color: #f7b614;
  font-size: 40px;
  font-weight: 800;
}

.presentation-container .info-presentation-container .position-one,
.presentation-container .info-presentation-container .position-two {
  height: 50%;
  max-height: 50%;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}

.presentation-container .info-presentation-container .position-one img,
.presentation-container .info-presentation-container .position-two img {
  height: 100%;
  max-height: 70%;
}

.presentation-container .info-presentation-container .position-two {
  justify-content: flex-end;
}

.presentation-container .image-presentation-container {
  overflow: hidden;
}

.presentation-container .image-presentation-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: -webkit-transform 2s, -webkit-filter 1s ease-in-out;
  transition: transform 2s, filter 1s ease-in-out;
  transition: transform 2s, filter 1s ease-in-out, -webkit-transform 2s, -webkit-filter 1s ease-in-out;
}

.presentation-container .image-presentation-container img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media screen and (min-width: 2000px) {
  .presentation-container {
    height: 500px;
  }
}

@media screen and (max-width: 1200px) {
  .presentation-container .info-presentation-container .info-position {
    position: absolute;
    z-index: 1;
    top: 15%;
    left: 7%;
  }
}

@media screen and (max-width: 960px) {
  .presentation-container {
    height: 300px;
  }
  .presentation-container .image-presentation-container {
    display: none;
  }
  .presentation-container .info-presentation-container {
    width: 100%;
    max-width: 100%;
  }
  .presentation-container .info-presentation-container .info-position {
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 10%;
  }
  .presentation-container .info-presentation-container .info-position .head-one {
    font-size: 30px;
    font-weight: 800;
  }
  .presentation-container .info-presentation-container .info-position .head-two {
    font-size: 30px;
    font-weight: 800;
  }
}

@media screen and (max-width: 520px) {
  .presentation-container .info-presentation-container .info-position {
    position: absolute;
    z-index: 1;
    top: 8%;
    left: 5%;
  }
}

@media screen and (max-width: 470px) {
  .presentation-container .info-presentation-container .info-position {
    position: absolute;
    z-index: 1;
    top: 15%;
    left: 5%;
  }
  .presentation-container .info-presentation-container .info-position .icon-div img {
    width: 60px;
    height: auto;
  }
  .presentation-container .info-presentation-container .info-position .icon-div .icon-one p,
  .presentation-container .info-presentation-container .info-position .icon-div .icon-two p,
  .presentation-container .info-presentation-container .info-position .icon-div .icon-three p,
  .presentation-container .info-presentation-container .info-position .icon-div .icon-four p {
    font-size: 3vmin;
  }
}

@media screen and (max-width: 400px) {
  .presentation-container .info-presentation-container .info-position .icon-div img {
    width: 50px;
    height: auto;
  }
}

@media screen and (max-width: 340px) {
  .presentation-container .info-presentation-container .info-position {
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 4%;
  }
}
/*# sourceMappingURL=Presentation.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.call-container {
  width: 100%;
  max-width: 100%;
  height: 400px;
  background-color: #32343a;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  background-image: url(../../static/media/pattern.c283d0d3.svg);
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
}

.call-container .main-content {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.call-container .main-content .phone-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60%;
  max-height: 60%;
}

.call-container .main-content .phone-div .first-div,
.call-container .main-content .phone-div .third-div {
  background-color: #f7b614;
  width: 30rem;
  height: 50%;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #32343a;
  border: 1px solid white;
}

.call-container .main-content .phone-div .first-div {
  margin-right: -5%;
  border-radius: 15px 0px 0px 15px;
}

.call-container .main-content .phone-div .third-div {
  margin-left: -5%;
  border-radius: 0px 15px 15px 0px;
}

.call-container .main-content .phone-div .third-div .hidden-third-div-element {
  display: none;
}

.call-container .main-content .phone-div .third-div img {
  width: 30px;
  max-width: 30px;
  height: auto;
}

.call-container .main-content .phone-div .middle-div {
  background-color: #cfd8e7;
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
  z-index: 2;
  border: 10px solid white;
  transition: all .4s;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-container .main-content .phone-div .middle-div img {
  width: auto;
  height: 100%;
}

.call-container .main-content .de-mango-bottom {
  width: 40%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.call-container .main-content .de-mango-bottom p {
  color: white;
  margin-top: 2%;
  font-weight: 500;
  -webkit-filter: drop-shadow(0px 0px 12px black);
          filter: drop-shadow(0px 0px 12px black);
}

.call-container .main-content .de-mango-top {
  height: 10%;
  display: flex;
  align-items: center;
  color: #f7b614;
}

.call-container .main-content .de-mango-top h2 span {
  color: white;
  cursor: auto;
}

.call-container .main-content .de-mango-bottom {
  height: 20%;
  max-height: 20%;
}

@media screen and (max-width: 1200px) {
  .call-container .main-content .phone-div .third-div {
    padding-left: 30px;
  }
  .call-container .main-content .phone-div .first-div,
  .call-container .main-content .phone-div .third-div {
    width: 25rem;
  }
  .call-container .main-content .de-mango-bottom {
    width: 50%;
  }
}

@media screen and (max-width: 1100px) {
  .call-container .main-content .phone-div .first-div,
  .call-container .main-content .phone-div .third-div {
    width: 21rem;
  }
}

@media screen and (max-width: 900px) {
  .call-container .main-content .third-div {
    padding-left: 35px;
  }
  .call-container .main-content .phone-div .first-div,
  .call-container .main-content .phone-div .third-div {
    font-size: 14px;
    width: 18rem;
  }
  .call-container .main-content .phone-div .third-div img {
    width: 25px;
    max-width: 25px;
    height: auto;
  }
}

@media screen and (max-width: 800px) {
  .call-container .main-content .phone-div .middle-div {
    width: 12rem;
    height: 12rem;
  }
  .call-container .main-content .phone-div .first-div,
  .call-container .main-content .phone-div .third-div {
    width: 16rem;
  }
  .call-container .main-content .de-mango-bottom {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .call-container .main-content .phone-div .first-div,
  .call-container .main-content .phone-div .third-div {
    font-size: 12px;
    width: 14rem;
  }
  .call-container .main-content .phone-div .third-div img {
    width: 23px;
    max-width: 23px;
    height: auto;
  }
}

@media screen and (max-width: 650px) {
  .call-container .main-content .phone-div .first-div,
  .call-container .main-content .phone-div .third-div {
    width: 13rem;
  }
  .call-container .main-content .phone-div .middle-div {
    width: 11.5rem;
    height: 11.5rem;
  }
}

@media screen and (max-width: 600px) {
  .call-container .main-content .phone-div {
    height: 50%;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .call-container .main-content .phone-div .first-div,
  .call-container .main-content .phone-div .third-div {
    width: 11.5rem;
  }
  .call-container .main-content .phone-div .middle-div {
    width: 10rem;
    height: 10rem;
  }
}

@media screen and (max-width: 530px) {
  .call-container .main-content .phone-div {
    width: 100%;
  }
  .call-container .main-content .phone-div .first-div {
    display: none;
  }
  .call-container .main-content .phone-div .third-div {
    height: 70%;
    padding-left: 5rem;
    align-items: flex-start;
    width: 17rem;
    margin-left: -10%;
  }
  .call-container .main-content .phone-div .third-div .hidden-third-div-element {
    display: inherit;
  }
  .call-container .main-content .phone-div .middle-div {
    width: 11rem;
    height: 11rem;
  }
}

@media screen and (max-width: 455px) {
  .call-container .main-content .de-mango-bottom p {
    font-size: 13px;
  }
  .call-container .main-content .phone-div .third-div {
    font-size: 10px;
    padding-left: 60px;
    width: 13rem;
  }
}

@media screen and (max-width: 400px) {
  .call-container .main-content .de-mango-top {
    font-size: 14px;
  }
  .call-container .main-content .de-mango-bottom {
    width: 90%;
  }
  .call-container .main-content .phone-div .third-div {
    padding-left: 44px;
    width: 11.5rem;
  }
  .call-container .main-content .phone-div .middle-div {
    width: 10rem;
    height: 10rem;
  }
}

@media screen and (max-width: 340px) {
  .call-container {
    height: 350px;
  }
  .call-container .main-content .phone-div .middle-div {
    width: 9rem;
    height: 9rem;
  }
  .call-container .main-content .phone-div .third-div {
    font-size: 9px;
    padding-left: 40px;
  }
  .call-container .main-content .phone-div .third-div img {
    width: 20px;
  }
}

@media screen and (max-width: 320px) {
  .call-container {
    height: 310px;
  }
  .call-container .main-content .de-mango-top {
    font-size: 12px;
  }
  .call-container .main-content .phone-div .third-div {
    font-size: 9px;
    height: 65%;
    width: 55%;
    padding-left: 40px;
  }
  .call-container .main-content .phone-div .third-div img {
    width: 20px;
  }
}
/*# sourceMappingURL=CallForOrder.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.aside-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  color: #32343a;
  padding: 20px 20px;
  background-image: url(../../static/media/asidephoto.d248fe76.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.aside-menu-container .aside-menu-dropdown {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aside-menu-container .aside-menu-food-type,
.aside-menu-container .store-stand-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 100vh;
  margin-top: 1rem;
  padding: 1rem 0;
  background-color: white;
  background-color: rgba(223, 221, 221, 0.7);
  border-radius: .6rem;
}

.aside-menu-container .aside-menu-food-type .aside-menu-header,
.aside-menu-container .aside-menu-food-type #check-filter,
.aside-menu-container .store-stand-menu-container .aside-menu-header,
.aside-menu-container .store-stand-menu-container #check-filter {
  display: none;
}

.aside-menu-container .aside-menu-food-type .store-stand-menu-content,
.aside-menu-container .store-stand-menu-container .store-stand-menu-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.aside-menu-container .aside-menu-food-type .store-stand-menu-content .yellow-circle,
.aside-menu-container .store-stand-menu-container .store-stand-menu-content .yellow-circle {
  display: none;
}

.aside-menu-container .aside-menu-food-type .store-stand-menu-close,
.aside-menu-container .store-stand-menu-container .store-stand-menu-close {
  display: none;
}

.aside-menu-container .aside-menu-food-type .menu-filter-container,
.aside-menu-container .aside-menu-food-type .unselected-content,
.aside-menu-container .store-stand-menu-container .menu-filter-container,
.aside-menu-container .store-stand-menu-container .unselected-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 40px;
  border-radius: 15px;
  margin-top: 5%;
  margin-bottom: 3%;
  padding: 2px 10px;
  background-color: white;
}

.aside-menu-container .aside-menu-food-type .menu-filter-container .font-awesome,
.aside-menu-container .aside-menu-food-type .unselected-content .font-awesome,
.aside-menu-container .store-stand-menu-container .menu-filter-container .font-awesome,
.aside-menu-container .store-stand-menu-container .unselected-content .font-awesome {
  display: none;
}

.aside-menu-container .aside-menu-food-type .menu-filter-container:hover,
.aside-menu-container .aside-menu-food-type .unselected-content:hover,
.aside-menu-container .store-stand-menu-container .menu-filter-container:hover,
.aside-menu-container .store-stand-menu-container .unselected-content:hover {
  cursor: pointer;
  background-color: #d4d3d3;
}

.aside-menu-container .aside-menu-food-type .menu-filter-container i,
.aside-menu-container .aside-menu-food-type .unselected-content i,
.aside-menu-container .store-stand-menu-container .menu-filter-container i,
.aside-menu-container .store-stand-menu-container .unselected-content i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aside-menu-container .aside-menu-food-type .menu-filter-container i img,
.aside-menu-container .aside-menu-food-type .unselected-content i img,
.aside-menu-container .store-stand-menu-container .menu-filter-container i img,
.aside-menu-container .store-stand-menu-container .unselected-content i img {
  width: 2rem;
  height: 2rem;
}

.aside-menu-container .aside-menu-food-type h3,
.aside-menu-container .store-stand-menu-container h3 {
  color: #585858;
}

.aside-menu-container .aside-menu-food-type {
  position: relative;
  overflow: hidden;
}

.aside-menu-container .hide-after-load {
  display: none;
}

.aside-menu-container .show-on-load {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.aside-menu-container .selected,
.aside-menu-container .menu-selected,
.aside-menu-container .selected-clone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 40px;
  border-radius: 15px;
  margin-top: 5%;
  margin-bottom: 3%;
  padding: 2px 10px;
  background-color: #f7b614;
}

.aside-menu-container .selected .font-awesome,
.aside-menu-container .menu-selected .font-awesome,
.aside-menu-container .selected-clone .font-awesome {
  display: none;
}

.aside-menu-container .selected:hover,
.aside-menu-container .menu-selected:hover,
.aside-menu-container .selected-clone:hover {
  cursor: pointer;
  background-color: #d4d3d3;
}

.aside-menu-container .selected i,
.aside-menu-container .menu-selected i,
.aside-menu-container .selected-clone i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aside-menu-container .selected i img,
.aside-menu-container .menu-selected i img,
.aside-menu-container .selected-clone i img {
  width: 2.1rem;
  height: 2.1rem;
}

@media screen and (max-width: 1100px) {
  .aside-menu-container {
    position: relative;
  }
  .aside-menu-container .store-stand-menu-container {
    width: 100%;
    background-color: transparent;
    height: 93.5vh;
    padding-top: 15vmin;
  }
  .aside-menu-container .store-stand-menu-container h3 {
    color: #f7b614;
    font-size: 6vmin;
  }
  .aside-menu-container .store-stand-menu-container .store-stand-menu-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .aside-menu-container .store-stand-menu-container .store-stand-menu-content::-webkit-scrollbar {
    display: none;
  }
  .aside-menu-container .store-stand-menu-container .menu-selected {
    background-color: transparent;
    color: #f7b614;
    font-size: 6vmin;
    border-radius: 0;
    border-bottom: 2px dashed white;
    border-width: 1px;
    width: 90%;
    font-weight: 800;
    padding: 0;
    margin-bottom: 2vmin;
    margin-top: 2vmin;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .aside-menu-container .store-stand-menu-container .menu-selected i {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #f7b614;
    width: 5vmin;
    height: 5vmin;
    position: relative;
  }
  .aside-menu-container .store-stand-menu-container .menu-selected i img {
    display: none;
  }
  .aside-menu-container .store-stand-menu-container .menu-selected i .yellow-circle {
    display: flex;
    width: 3vmin;
    height: 3vmin;
    background-color: #f7b614;
  }
  .aside-menu-container .store-stand-menu-container .unselected-content {
    background-color: transparent;
    color: white;
    font-size: 6vmin;
    border-radius: 0;
    border-bottom: 2px dashed white;
    border-width: 1px;
    width: 90%;
    font-weight: 800;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 2vmin;
    margin-top: 2vmin;
    padding: 0;
  }
  .aside-menu-container .store-stand-menu-container .unselected-content i {
    display: flex;
    align-items: center;
    border-radius: 50%;
    border: 2px solid white;
    width: 5vmin;
    height: 5vmin;
  }
  .aside-menu-container .store-stand-menu-container .unselected-content i img,
  .aside-menu-container .store-stand-menu-container .unselected-content i .yellow-circle {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .aside-menu-container .store-stand-menu-container h3 {
    color: #f7b614;
    font-size: 7vmin;
  }
  .aside-menu-container .store-stand-menu-container .menu-selected,
  .aside-menu-container .store-stand-menu-container .unselected-content {
    font-size: 6vmin;
  }
}

@media screen and (max-width: 670px) {
  .aside-menu-container {
    flex-direction: row;
    padding: 0;
    background-image: none;
    color: #808080;
  }
  .aside-menu-container .aside-menu-food-type {
    margin-top: 0;
    border-radius: 0;
    align-items: flex-start;
    background-color: white;
  }
  .aside-menu-container .aside-menu-food-type .h3-hidden {
    display: none;
  }
  .aside-menu-container .aside-menu-food-type .hidden-aside {
    display: none;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header .dropdown {
    transition: ease-in-out .5s;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown {
    z-index: 100;
    align-items: flex-start;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header p {
    font-size: 5vmin;
    font-weight: 800;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header .selected {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: transparent;
    color: #f7b614;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header #check-filter:checked ~ .dropdown {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header #check-filter:checked ~ .aside-menu-dropdown {
    left: 0;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header #check-filter:checked ~ html,
  .aside-menu-container .aside-menu-food-type .aside-menu-header #check-filter:checked body {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown {
    position: fixed;
    top: 11.3rem;
    left: -100%;
    display: flex;
    flex-direction: column;
    padding: 2vmin 5vmin;
    width: 100%;
    overflow-y: scroll;
    height: 80%;
    transition: ease-in-out .5s;
    z-index: 95;
    background: linear-gradient(to bottom, white 0%, white 0%, rgba(255, 255, 255, 0.95) 60%, white 60%, rgba(255, 255, 255, 0.95) 60%, rgba(255, 255, 255, 0.7) 100%);
    /* Hide scrollbar for Chrome, Safari and Opera */
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown::-webkit-scrollbar {
    display: none;
  }
  .aside-menu-container .aside-menu-food-type .menu-filter-container,
  .aside-menu-container .aside-menu-food-type .selected {
    flex-direction: row-reverse;
    justify-content: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    background-color: transparent;
  }
  .aside-menu-container .aside-menu-food-type .menu-filter-container i,
  .aside-menu-container .aside-menu-food-type .selected i {
    margin-right: 1rem;
  }
  .aside-menu-container .aside-menu-food-type .menu-filter-container i img,
  .aside-menu-container .aside-menu-food-type .selected i img {
    width: 10vmin;
    height: 10vmin;
  }
  .aside-menu-container .aside-menu-food-type .menu-filter-container p,
  .aside-menu-container .aside-menu-food-type .selected p {
    font-size: 8vmin;
  }
  .aside-menu-container .aside-menu-food-type .selected-clone {
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 100%;
    background-color: transparent;
    color: #f7b614;
  }
  .aside-menu-container .aside-menu-food-type .selected-clone i {
    margin-right: 1rem;
  }
  .aside-menu-container .aside-menu-food-type .selected-clone i img {
    width: 10vmin;
    height: 10vmin;
  }
  .aside-menu-container .aside-menu-food-type .selected-clone p {
    font-size: 5vmin;
  }
  .aside-menu-container .aside-menu-food-type .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .aside-menu-container .aside-menu-food-type .dropdown i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .aside-menu-container .aside-menu-food-type .dropdown i img {
    width: 10vmin;
    height: 10vmin;
  }
}

@media screen and (max-width: 550px) {
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown {
    top: 11rem;
  }
}

@media screen and (max-width: 490px) {
  .aside-menu-container .store-stand-menu-container {
    padding-top: 17vmin;
  }
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown {
    top: 10.8rem;
  }
}

@media screen and (max-width: 450px) {
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown {
    top: 10.4rem;
  }
}

@media screen and (max-width: 390px) {
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown {
    top: 9.7rem;
    height: 77%;
  }
}

@media screen and (max-width: 350px) {
  .aside-menu-container .aside-menu-food-type .aside-menu-header .aside-menu-dropdown {
    top: 9.5rem;
    height: 75%;
  }
}
/*# sourceMappingURL=AsideMenu.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}
/*# sourceMappingURL=MainStyle.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.partners-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: 92.2vh;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
}

.partners-container .partners-side-menu,
.partners-container .partners-right-side-photo {
  width: 25%;
  border-right: 1px solid lightgray;
  background-color: white;
}

.partners-container .partners-right-side-photo {
  overflow: hidden;
  background-image: url(../../static/media/friesbw.d44c139a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.partners-container .partners-show-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.partners-container .partners-show-container .partners-display-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.display-container-wrapper {
  position: relative;
  width: 33.33%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.single-display-unit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  max-width: 12.5rem;
  min-width: 12.5rem;
  height: 18.7rem;
  border: 2px solid lightgray;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px 5px;
  color: #32343a;
  background-color: white;
  cursor: pointer;
  margin-left: 1.5rem;
  margin-right: 2rem;
  overflow: hidden;
}

.single-display-unit p {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  color: #808080;
}

.single-display-unit .display-unit-price {
  color: #f7b614;
}

.single-display-unit:hover {
  box-shadow: 18px 14px 28px -12px rgba(0, 0, 0, 0.4);
}

.single-display-unit .display-unit-image {
  width: 100%;
  min-height: 50%;
  max-height: 50%;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.single-display-unit .display-unit-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.symbolic-photo {
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
}

.symbolic-photo p {
  color: #303030;
}

.single-partner-display-unit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  height: 20rem;
  width: 23rem;
  border-radius: 10px;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  color: #32343a;
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.single-partner-display-unit .single-dispaly-unit-patner-img {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 2px solid #f7b614;
  overflow: hidden;
}

.single-partner-display-unit .single-dispaly-unit-patner-img img {
  width: 100%;
  height: 100%;
}

.single-partner-display-unit .single-partner-display-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, white 0%, white 0%, rgba(255, 255, 255, 0.95) 70%, white 70%, rgba(255, 255, 255, 0.95) 70%, rgba(255, 255, 255, 0.7) 100%);
  width: 101%;
  padding-top: .3rem;
  padding-bottom: .3rem;
  text-align: left;
  padding-left: .5rem;
}

.single-partner-display-unit .partner-address,
.single-partner-display-unit .partner-delivery {
  color: #585858;
  width: 100%;
}

.single-partner-display-unit .partner-address {
  font-weight: 300;
}

.single-partner-display-unit p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.single-partner-display-unit:hover {
  box-shadow: 10px 10px 10px 0px rgba(128, 128, 128, 0.3);
}

.single-partner-display-unit .display-unit-image {
  min-width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.single-partner-display-unit .display-unit-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.store-closed {
  position: absolute;
  z-index: 89;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.store-closed img {
  width: 80%;
}

.store-closed:hover ~ .single-partner-display-unit {
  box-shadow: 10px 10px 10px 0px rgba(128, 128, 128, 0.5);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

@media screen and (min-width: 2350px) {
  .single-partner-display-unit {
    width: 27rem;
  }
}

@media screen and (max-width: 1975px) {
  .display-container-wrapper {
    width: 50%;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1975px) {
  .single-partner-display-unit {
    width: 29rem;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .single-partner-display-unit {
    width: 27rem;
  }
}

@media screen and (max-width: 1400px) {
  .partners-container {
    min-height: 100%;
  }
  .partners-container .partners-side-menu {
    min-width: 25%;
    min-height: 100%;
  }
  .partners-container .partners-right-side-photo {
    display: none;
  }
  .partners-container .partners-show-container {
    height: 90%;
  }
  .partners-container .partners-show-container .partners-display-container {
    padding: 1rem 1rem;
  }
}

@media screen and (max-width: 1070px) {
  .partners-container .partners-side-menu {
    min-width: 35%;
    min-height: 100%;
  }
  .display-container-wrapper {
    width: 100%;
  }
  .single-partner-display-unit {
    border: 1px solid rgba(128, 128, 128, 0.3);
    box-shadow: 10px 10px 10px 0px rgba(128, 128, 128, 0.3);
  }
}

@media screen and (max-width: 900px) {
  .partners-container {
    min-height: 100%;
  }
  .partners-container .partners-right-side-photo {
    display: none;
  }
  .partners-container .partners-show-container .partners-display-container {
    min-height: 100%;
  }
}

@media screen and (min-width: 670px) {
  .partners-container .partners-show-container .partners-display-container {
    min-height: 100%;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .partners-container {
    margin-top: 5rem;
  }
  .single-display-unit p {
    font-size: 10px;
  }
}

@media screen and (max-width: 670px) {
  .display-container-wrapper {
    width: 50%;
  }
  .partners-container {
    flex-direction: column;
    min-height: 100%;
    background-color: white;
    margin-top: 5rem;
  }
  .partners-container .partners-side-menu {
    min-width: 100%;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    background-image: none;
  }
  .partners-container .partners-show-container {
    min-width: 100%;
    height: 100%;
  }
  .partners-container .partners-show-container .partners-display-container {
    justify-content: space-between;
  }
  .single-partner-display-unit {
    max-width: 14rem;
    min-width: 14rem;
  }
}

@media screen and (max-width: 540px) {
  .partners-container .partners-show-container .partners-display-container {
    justify-content: center;
  }
  .partners-container .single-partner-display-unit {
    max-width: 80%;
    min-width: 80%;
    border: 2px solid lightgrey;
    box-shadow: 5px 5px 7px 2px rgba(128, 128, 128, 0.3);
    height: 15rem;
    padding: 0px 0px 5px 0;
  }
  .partners-container .single-partner-display-unit .display-unit-image {
    min-width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .partners-container .single-partner-display-unit .display-unit-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .display-container-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .partners-container .single-partner-display-unit {
    max-width: 100%;
    min-width: 100%;
  }
}
/*# sourceMappingURL=Partners.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.shopping-cart-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  height: 400px;
  background-color: white;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  position: -webkit-sticky;
  position: sticky;
  top: 7rem;
}

.shopping-cart-container .shopping-cart-header,
.shopping-cart-container .shopping-cart-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 9%;
  width: 100%;
}

.shopping-cart-container .shopping-cart-header .empty-shopping-cart-button {
  background: transparent;
  border: 0;
  outline: 0;
  width: auto;
  height: auto;
  border-radius: 200px;
  transition: background 0.8s;
}

.shopping-cart-container .shopping-cart-header .empty-shopping-cart-button:hover {
  background: white radial-gradient(circle, transparent 1%, white 1%) center/15000%;
}

.shopping-cart-container .shopping-cart-header .empty-shopping-cart-button:active {
  background-color: #f7b614;
  background-size: 100%;
  transition: background 0s;
}

.shopping-cart-container .shopping-cart-header .empty-shopping-cart-button .empty-cart-responsive {
  display: none;
}

.shopping-cart-container .shopping-cart-footer .shopping-cart-button {
  width: 5rem;
  height: 1.87rem;
  background-color: #f7b614;
  border: 0;
  outline: 0;
  border-radius: 5px;
  color: #32343a;
  transition: background 0.8s;
}

.shopping-cart-container .shopping-cart-footer .shopping-cart-button:hover {
  background: #f7b614 radial-gradient(circle, transparent 1%, #f7b614 1%) center/15000%;
}

.shopping-cart-container .shopping-cart-footer .shopping-cart-button:active {
  background-color: white;
  background-size: 100%;
  transition: background 0s;
}

.shopping-cart-container .shopping-cart-body {
  height: 75%;
  width: 100%;
  overflow-y: scroll;
}

.shopping-cart-container .shopping-cart-body::-webkit-scrollbar {
  display: none;
}

.empty-shopping-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  color: #32343a;
}

.empty-shopping-cart-container img {
  max-width: 90px;
  height: auto;
}

.empty-shopping-cart-container p {
  margin-top: 3%;
  font-size: 18px;
}

.single-cart-item-additional {
  font-size: .5rem;
}

.shopping-cart-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 27%;
  margin-bottom: 3%;
  border-radius: 5px;
  border: 0;
  padding: 5px;
  background-color: whitesmoke;
}

.shopping-cart-item-container .shopping-cart-item-image {
  width: 25%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.shopping-cart-item-container .shopping-cart-item-image img {
  width: 100%;
  object-fit: cover;
}

.shopping-cart-item-container .shopping-cart-item-info {
  display: flex;
  flex-direction: column;
  width: 72%;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50%;
  font-size: 14px;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .single-item-header-delete-button {
  background: none;
  outline: none;
  border: 0;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .delete-shopping-cart-item {
  display: none;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 50%;
  font-size: 14px;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons .single-cart-item-info-footer-button {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 20px;
  font-weight: 800;
  color: #32343a;
  width: 23%;
  height: 100%;
  background-position: center;
  transition: background 0.8s;
  cursor: pointer;
  border-radius: 200px;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons .single-cart-item-info-footer-button .delete-shopping-cart-item {
  display: none;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons .single-cart-item-info-footer-button:hover {
  background: whitesmoke radial-gradient(circle, transparent 1%, whitesmoke 1%) center/15000%;
}

.shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons .single-cart-item-info-footer-button:active {
  background-color: #f7b614;
  background-size: 100%;
  transition: background 0s;
}

@media screen and (min-width: 1800px) {
  .single-cart-item-additional {
    font-size: .6rem;
  }
}

@media screen and (min-width: 1800px) {
  .shopping-cart-item-container {
    height: 30%;
  }
}

@media screen and (max-width: 1100px) {
  .single-cart-item-additional {
    font-size: 2.6vmin;
    color: white;
  }
  .shopping-cart-container .shopping-cart-header {
    margin-top: 1.5rem;
  }
  .shopping-cart-container .shopping-cart-header .header-shopping-cart-text {
    display: none;
  }
  .shopping-cart-container .shopping-cart-header .empty-shopping-cart-button .empty-cart-responsive {
    display: flex;
    width: 7vmin;
    height: 7vmin;
  }
  .shopping-cart-container .shopping-cart-header .empty-shopping-cart-button .empty-cart-regular {
    display: none;
  }
  .shopping-cart-container .shopping-cart-header .empty-shopping-cart-button:hover {
    background: none;
  }
  .shopping-cart-container .shopping-cart-item-container {
    background-color: transparent;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-image {
    border-radius: 1rem;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info {
    width: 70%;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .shopping-cart-quantity,
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .shopping-cart-quantity {
    color: #f7b614;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer p,
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header p {
    color: white;
    font-size: 5vmin;
    font-weight: 600;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .single-item-header-delete-button .delete-shopping-cart-item {
    display: flex;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .single-item-header-delete-button .flaticon-delete-shopping-cart-item {
    display: none;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .single-item-header-delete-button i {
    color: white;
    font-size: 5vmin;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons {
    justify-content: space-between;
    align-items: flex-end;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons .delete-shopping-cart-item {
    display: flex;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons .single-cart-item-info-footer-button {
    color: #f7b614;
    font-size: 7vmin;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons .single-cart-item-info-footer-button:hover {
    background: none;
  }
  .shopping-cart-container .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-footer .single-item-info-footer-buttons i {
    color: white;
    font-size: 5vmin;
  }
  .shopping-cart-container .shopping-cart-body {
    border-top: 4px dotted #808080;
    border-bottom: 4px dotted #808080;
    padding-top: 2vmin;
  }
  .shopping-cart-container .shopping-cart-footer {
    margin-bottom: 3rem;
  }
  .shopping-cart-container .shopping-cart-footer .shopping-cart-button {
    width: 30vmin;
    height: 8vmin;
    color: white;
    font-size: 3vmin;
    font-weight: 600;
  }
  .shopping-cart-container .shopping-cart-footer p {
    color: white;
    font-size: 5vmin;
  }
  .empty-shopping-cart-container img {
    max-width: 10rem;
    height: 10rem;
  }
  .empty-shopping-cart-container p {
    font-size: 4vmin;
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .shopping-cart-container .shopping-cart-header .empty-shopping-cart-button .empty-cart-responsive {
    width: 10vmin;
    height: 10vmin;
  }
}
/*# sourceMappingURL=ShoppingCart.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.store-item-display-container {
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: flex-end;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.store-item-display-container .store-single-item-display {
  position: relative;
  margin: auto;
  border: 0;
  border-radius: 10px;
  width: 700px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  z-index: 70;
}

.store-item-display-container .store-single-item-display .single-item-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-height: 30%;
  color: #969292;
}

.store-item-display-container .store-single-item-display .single-item-header h2 {
  color: #32343a;
  margin-bottom: 2%;
}

.store-item-display-container .store-single-item-display .single-item-header .single-item-image {
  width: 25%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.store-item-display-container .store-single-item-display .single-item-header .single-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: ease-in-out .2s;
}

.store-item-display-container .store-single-item-display .single-item-header .single-item-name {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.store-item-display-container .store-single-item-display .single-item-header .single-item-name .single-item-span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.store-item-display-container .store-single-item-display .single-item-header .single-item-name .single-item-span span {
  margin-bottom: 2%;
  color: #32343a;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.store-item-display-container .store-single-item-display .single-item-header .single-item-name .single-item-span span:hover {
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
  color: #f7b614;
}

.store-item-display-container .store-single-item-display .single-item-body {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 55%;
  min-height: 10.75rem;
  max-height: 50vh;
  margin-top: 2%;
  margin-bottom: 5%;
}

.store-item-display-container .store-single-item-display .single-item-body label {
  text-align: center;
}

.store-item-display-container .store-single-item-display .single-item-body .charged-add-ons {
  color: #f7b614;
}

.store-item-display-container .store-single-item-display .single-item-body .single-item-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.store-item-display-container .store-single-item-display .single-item-body .input-yellow-circle {
  display: none;
}

.store-item-display-container .store-single-item-display .single-item-body input[type="checkbox"],
.store-item-display-container .store-single-item-display .single-item-body input[type='radio'] {
  visibility: hidden;
}

.store-item-display-container .store-single-item-display .single-item-body .input-black-circle {
  border: 2px solid #585858;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.store-item-display-container .store-single-item-display .single-item-body .input-black-circle i {
  background-color: transparent;
  width: .7rem;
  height: .7rem;
  border-radius: 50%;
}

.store-item-display-container .store-single-item-display .single-item-body input[type='radio']:checked ~ .input-black-circle i {
  background-color: #585858;
}

.store-item-display-container .store-single-item-display .single-item-body .input-black-box {
  border: 2px solid #585858;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: white;
  font-size: .7rem;
}

.store-item-display-container .store-single-item-display .single-item-body .input-black-box i {
  display: none;
}

.store-item-display-container .store-single-item-display .single-item-body input[type="checkbox"]:checked ~ .input-black-box {
  background-color: #585858;
}

.store-item-display-container .store-single-item-display .single-item-body input[type="checkbox"]:checked ~ .input-black-box i {
  display: flex;
}

.store-item-display-container .store-single-item-display .single-item-body input {
  margin-top: 2%;
}

.store-item-display-container .store-single-item-display .single-item-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 10%;
}

.store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity .quantity-button {
  outline: 0;
  border: 0;
  background-color: white;
  font-size: 2rem;
  color: #585858;
  width: 2rem;
  border-radius: 50%;
  transition: background 0.8s;
}

.store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity .quantity-button:hover {
  background: white radial-gradient(circle, transparent 1%, white 1%) center/15000%;
}

.store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity .quantity-button:active {
  background-color: #f7b614;
  background-size: 100%;
  transition: background 0s;
}

.store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity h1 {
  margin: 0 1rem;
}

.store-item-display-container .store-single-item-display .single-item-footer .single-item-add-to-cart {
  width: 200px;
  height: 50px;
  font-size: 15px;
  font-weight: 800;
  border-radius: 5px;
  border: 0;
  color: white;
  outline: 0;
  background-color: #32343a;
}

.store-item-display-container .store-single-item-display .single-item-footer .single-item-add-to-cart:hover {
  background-color: #5e626b;
}

.store-item-display-container .store-single-item-display .single-item-body,
.store-item-display-container .store-single-item-display .single-item-footer {
  color: #969292;
}

.store-item-display-container .store-single-item-display .single-item-body h3,
.store-item-display-container .store-single-item-display .single-item-body h4,
.store-item-display-container .store-single-item-display .single-item-footer h3,
.store-item-display-container .store-single-item-display .single-item-footer h4 {
  color: #f7b614;
  margin-top: 2%;
}

@media screen and (max-width: 850px) {
  .store-item-display-container {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
  }
  .store-item-display-container .store-single-item-display {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 8vmin 2vmin 15vmin 2vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-header {
    color: white;
    font-size: 3vmin;
    height: 30vmin;
    flex-wrap: nowrap;
  }
  .store-item-display-container .store-single-item-display .single-item-header h2 {
    color: white;
    font-size: 5vmin;
    margin-bottom: 0;
  }
  .store-item-display-container .store-single-item-display .single-item-header .single-item-image {
    width: 30vmin;
    border: 2px solid #f7b614;
  }
  .store-item-display-container .store-single-item-display .single-item-header .single-item-name {
    position: relative;
    padding-left: 1rem;
  }
  .store-item-display-container .store-single-item-display .single-item-header .single-item-name .single-item-span {
    align-items: flex-start;
  }
  .store-item-display-container .store-single-item-display .single-item-header .single-item-name .single-item-span span {
    position: absolute;
    bottom: 26vmin;
    left: 90%;
    color: white;
    font-size: 5vmin;
    margin-bottom: 0;
  }
  .store-item-display-container .store-single-item-display .single-item-header .single-item-name .single-item-span span:hover {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    color: #f7b614;
  }
  .store-item-display-container .store-single-item-display .single-item-body {
    height: 45vmax;
    margin-top: 0;
    margin-bottom: 0;
  }
  .store-item-display-container .store-single-item-display .single-item-body .single-item-portion-size {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    border-bottom: 1vmin dotted #808080;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .store-item-display-container .store-single-item-display .single-item-body .single-item-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 33.33%;
    margin-bottom: 2vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-body input,
  .store-item-display-container .store-single-item-display .single-item-body label {
    margin-bottom: 3px;
  }
  .store-item-display-container .store-single-item-display .single-item-body .input-yellow-circle {
    border: 2px solid white;
    border-radius: 50%;
    width: 5vmin;
    height: 5vmin;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .store-item-display-container .store-single-item-display .single-item-body .input-yellow-circle i {
    border-radius: 50%;
    width: 3vmin;
    height: 3vmin;
    background-color: transparent;
  }
  .store-item-display-container .store-single-item-display .single-item-body input[type='radio'],
  .store-item-display-container .store-single-item-display .single-item-body .input-black-box,
  .store-item-display-container .store-single-item-display .single-item-body .input-black-circle {
    display: none;
  }
  .store-item-display-container .store-single-item-display .single-item-body input[type='radio']:checked ~ .input-yellow-circle,
  .store-item-display-container .store-single-item-display .single-item-body input[type="checkbox"]:checked ~ .input-yellow-circle {
    border: 2px solid #f7b614;
  }
  .store-item-display-container .store-single-item-display .single-item-body input[type='radio']:checked ~ .input-yellow-circle i,
  .store-item-display-container .store-single-item-display .single-item-body input[type="checkbox"]:checked ~ .input-yellow-circle i {
    background-color: #f7b614;
  }
  .store-item-display-container .store-single-item-display .single-item-body input[type='radio']:checked ~ label,
  .store-item-display-container .store-single-item-display .single-item-body input[type="checkbox"]:checked ~ label {
    color: #f7b614;
  }
  .store-item-display-container .store-single-item-display .single-item-footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 1rem;
    position: relative;
  }
  .store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity {
    padding-right: 4vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity .quantity-button {
    background-color: transparent;
    color: #f7b614;
    font-size: 10vmin;
    font-weight: 700;
  }
  .store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity .quantity-button:hover {
    background: none;
  }
  .store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity .quantity-button:active {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  .store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity h1 {
    font-size: 8vmin;
    color: #f7b614;
  }
  .store-item-display-container .store-single-item-display .single-item-footer .single-item-add-to-cart {
    position: absolute;
    top: 15vmin;
    left: 30vmin;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2vmin 3vmin;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 4vmin;
    background-color: #f7b614;
  }
  .store-item-display-container .store-single-item-display .single-item-body,
  .store-item-display-container .store-single-item-display .single-item-footer {
    color: white;
    font-size: 3vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-body h3,
  .store-item-display-container .store-single-item-display .single-item-footer h3 {
    font-size: 4vmin;
  }
}

@media screen and (max-width: 768px) {
  .store-item-display-container .store-single-item-display .single-item-header {
    font-size: 4vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-header h2 {
    color: white;
    font-size: 7vmin;
    margin-bottom: 0;
  }
  .store-item-display-container .store-single-item-display .single-item-body .input-yellow-circle {
    width: 6vmin;
    height: 6vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-body .input-yellow-circle i {
    width: 4vmin;
    height: 4vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-body,
  .store-item-display-container .store-single-item-display .single-item-footer {
    color: white;
    font-size: 4vmin;
  }
  .store-item-display-container .store-single-item-display .single-item-body h3,
  .store-item-display-container .store-single-item-display .single-item-footer h3 {
    font-size: 5.5vmin;
  }
}

@media screen and (max-width: 392px) {
  .store-item-display-container .store-single-item-display .single-item-footer {
    padding-bottom: 1.3rem;
  }
  .store-item-display-container .store-single-item-display .single-item-footer .single-item-footer-quantity h1 {
    margin: 0 0.4rem;
  }
}

@media screen and (max-width: 315px) {
  .store-item-display-container .store-single-item-display .single-item-footer {
    padding-bottom: 1.5rem;
  }
}
/*# sourceMappingURL=StoreItemDisplayModal.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.store-stand-informations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.store-stand-informations-container h3,
.store-stand-informations-container h4 {
  color: #585858;
}

.store-stand-informations-container .address-and-work-hours {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  padding: 0 4rem;
}

.store-stand-informations-container .address-and-work-hours .address {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-stand-informations-container .address-and-work-hours .work-days {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #dbdbdb;
  width: 15rem;
  height: 14rem;
  margin-top: 1rem;
  box-shadow: 3px 11px 18px -8px #585858;
  overflow: hidden;
}

.store-stand-informations-container .address-and-work-hours .work-days span {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  color: #808080;
  margin-top: 5px;
  padding: 0 1rem;
}

.store-stand-informations-container .address-and-work-hours .work-days .selected-day {
  background-color: white;
  box-shadow: -1px 9px 9px -5px rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 1.9rem;
  color: #585858;
  font-weight: bold;
}

.store-stand-informations-container .address-and-work-hours .work-days .shown-address {
  margin-top: 6px;
  font-size: .8rem;
  color: #808080;
  min-width: 100%;
  padding: 0 1rem;
}

.store-stand-informations-container .address-and-work-hours .work-days .shown-address span {
  color: #585858;
  font-weight: bold;
}

.store-stand-informations-container .address-and-work-hours .address-location {
  background-color: #dbdbdb;
  width: 20rem;
  height: 14rem;
  margin-top: 1rem;
  box-shadow: 3px 11px 18px -8px #585858;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.store-stand-informations-container .address-and-work-hours .address-location img {
  height: 100%;
}

.store-stand-informations-container .address-and-work-hours .store-stand-combobox {
  padding-left: 3rem;
  display: flex;
  flex-direction: row;
}

.store-stand-informations-container .address-and-work-hours .store-stand-combobox label {
  margin-right: 2rem;
  color: #585858;
}

.store-stand-informations-container .address-and-work-hours .store-stand-combobox select {
  border: 0;
  outline: 0;
  background-color: white;
  color: #585858;
  padding-right: 4px;
}

.store-stand-informations-container .informations {
  min-width: 100%;
  padding: 0 4rem;
  margin: 3rem 0;
}

.store-stand-informations-container .informations p {
  color: #808080;
  margin-top: .7rem;
}

.store-stand-informations-container .rating-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
  min-width: 100%;
}

.store-stand-informations-container .rating-group .rating {
  font-size: 1.1rem;
  color: #585858;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* ------- DO NOT EDIT BELOW --------- */
}

.store-stand-informations-container .rating-group .rating p {
  margin-top: 1rem;
}

.store-stand-informations-container .rating-group .rating .chart-two {
  width: 150px;
  height: 150px;
  margin: 0;
  position: relative;
}

.store-stand-informations-container .rating-group .rating .chart-two.animate svg .circle-foreground {
  -webkit-animation: offset 3s ease-in-out forwards;
          animation: offset 3s ease-in-out forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.store-stand-informations-container .rating-group .rating .chart-two.animate figcaption:after {
  -webkit-animation: chart-two-label 3s steps(70) forwards;
          animation: chart-two-label 3s steps(70) forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.store-stand-informations-container .rating-group .rating .chart-two svg {
  width: 100%;
  height: 100%;
}

.store-stand-informations-container .rating-group .rating .chart-two svg .circle-background, .store-stand-informations-container .rating-group .rating .chart-two svg .circle-foreground {
  r: 70px;
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke-width: 10px;
}

.store-stand-informations-container .rating-group .rating .chart-two svg .circle-foreground {
  stroke: #f7b614;
  stroke-dasharray: 307.72px 439.6px;
  stroke-dashoffset: 307.72px;
  stroke-linecap: round;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.store-stand-informations-container .rating-group .rating .chart-two figcaption {
  display: inline-block;
  width: 100%;
  height: 2rem;
  overflow: hidden;
  text-align: center;
  color: #585858;
  position: absolute;
  top: calc(50% - 1rem);
  left: 0;
  font-size: 0;
}

.store-stand-informations-container .rating-group .rating .chart-two figcaption:after {
  display: inline-block;
  content: "0%\a 1%\a 2%\a 3%\a 4%\a 5%\a 6%\a 7%\a 8%\a 9%\a 10%\a 11%\a 12%\a 13%\a 14%\a 15%\a 16%\a 17%\a 18%\a 19%\a 20%\a 21%\a 22%\a 23%\a 24%\a 25%\a 26%\a 27%\a 28%\a 29%\a 30%\a 31%\a 32%\a 33%\a 34%\a 35%\a 36%\a 37%\a 38%\a 39%\a 40%\a 41%\a 42%\a 43%\a 44%\a 45%\a 46%\a 47%\a 48%\a 49%\a 50%\a 51%\a 52%\a 53%\a 54%\a 55%\a 56%\a 57%\a 58%\a 59%\a 60%\a 61%\a 62%\a 63%\a 64%\a 65%\a 66%\a 67%\a 68%\a 69%\a 70%\a 71%\a 72%\a 73%\a 74%\a 75%\a 76%\a 77%\a 78%\a 79%\a 80%\a 81%\a 82%\a 83%\a 84%\a 85%\a 86%\a 87%\a 88%\a 89%\a 90%\a 91%\a 92%\a 93%\a 94%\a 95%\a 96%\a 97%\a 98%\a 99%\a 100%\a";
  white-space: pre;
  font-size: 2rem;
  line-height: 2rem;
}

@-webkit-keyframes chart-two-label {
  100% {
    -webkit-transform: translateY(-140rem);
            transform: translateY(-140rem);
  }
}

@keyframes chart-two-label {
  100% {
    -webkit-transform: translateY(-140rem);
            transform: translateY(-140rem);
  }
}

@-webkit-keyframes offset {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes offset {
  100% {
    stroke-dashoffset: 0;
  }
}

.store-stand-rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
}

.store-stand-rating-container .no-ratings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  color: #808080;
}

.store-stand-rating-container .no-ratings img {
  width: 8rem;
  height: 8rem;
  margin-bottom: 2rem;
}

.store-stand-rating-container .no-ratings a {
  color: #808080;
}

.store-stand-rating-container .single-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  color: #808080;
  border-bottom: 1px solid #d6d6d6;
  padding: 2rem 0;
}

.store-stand-rating-container .single-rating .single-rating-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #808080;
  align-items: center;
}

.store-stand-rating-container .single-rating .single-rating-header i {
  min-width: 2rem;
  min-height: 2rem;
  background-color: #808080;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 30%;
  border-radius: 50%;
  font-weight: bold;
}

.store-stand-rating-container .single-rating .single-rating-header h4 {
  color: #32343a;
  margin: 0 .7rem;
}

.store-stand-rating-container .single-rating .single-rating-header .store-rating {
  display: flex;
  align-items: center;
  margin-left: 23rem;
}

.store-stand-rating-container .single-rating .single-rating-header .store-rating .rating {
  color: #f7b614;
}

.store-stand-rating-container .single-rating .single-rating-header .store-rating img {
  max-width: 1rem;
  max-height: auto;
  margin-right: .5rem;
}

.store-stand-rating-container .single-rating .single-rating-body {
  text-align: justify;
  margin: 2rem 0;
  padding: 0 2.7rem;
}

.store-stand-rating-container .single-rating .single-rating-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 100%;
  padding: 0 2.7rem;
}

.store-stand-rating-container .single-rating .single-rating-footer span {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #808080;
}

.store-stand-rating-container .single-rating .single-rating-footer span h4 {
  margin: 0 .7rem;
}

.store-stand-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
}

.store-stand-container .store-stand-header {
  width: 100%;
  display: flex;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.store-stand-container .store-stand-header .store-stand-info {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  min-width: 100%;
  height: 27rem;
}

.store-stand-container .store-stand-header .store-stand-info .add-to-favorite {
  position: absolute;
  top: 9rem;
  right: 3rem;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  outline: none;
}

.store-stand-container .store-stand-header .store-stand-info .not-added-to-favorites {
  background-image: url(../../static/media/addtofavorite.9393da2d.svg);
}

.store-stand-container .store-stand-header .store-stand-info .not-added-to-favorites:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.store-stand-container .store-stand-header .store-stand-info .not-added-to-favorites:hover ~ .tooltip .tooltiptext-not-added {
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: ease-in-out .5s;
}

.store-stand-container .store-stand-header .store-stand-info .added-to-favorites {
  background-image: url(../../static/media/addedtofav.63c9b6de.svg);
}

.store-stand-container .store-stand-header .store-stand-info .added-to-favorites:hover ~ .tooltip .tooltiptext-added {
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: ease-in-out .5s;
}

.store-stand-container .store-stand-header .store-stand-info .tooltip {
  display: inline-block;
  border-bottom: 1px solid white;
  color: white;
  visibility: hidden;
}

.store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-not-added,
.store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-added {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  border: 2px solid #f7b614;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 52;
  top: 9rem;
  right: 7rem;
  transition: ease-in-out .5s;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-not-added::after,
.store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-added::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #f7b614;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content {
  display: flex;
  flex-direction: row;
  padding: .5rem 3rem;
  min-width: 55%;
  justify-content: space-between;
  border-radius: 1rem 1rem 0 0;
  background-color: rgba(255, 255, 255, 0.7);
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #32343a;
  width: 60%;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name .name-rating {
  display: flex;
  flex-direction: row;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name .name {
  font-weight: 600;
  font-size: 1.3rem;
  margin-right: 1rem;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name .info {
  font-size: .9rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .9rem;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours .store-work-hours,
.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours .delivery-work-hours {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-rating {
  display: flex;
  align-items: center;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-rating .rating {
  color: #32343a;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-rating img {
  max-width: 1rem;
  max-height: auto;
  margin-right: .2rem;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #32343a;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .delivery-price-info {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #585858;
  border-radius: 50%;
  margin-left: .5rem;
  margin-right: .5rem;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .delivery-price-info i {
  color: #585858;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .delivery-price-info:hover {
  border-color: #f7b614;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .store-stand-info-body-icons {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body img {
  max-width: 2.2rem;
  max-height: auto;
  margin-right: .5rem;
  margin-left: .8rem;
}

.store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .menu-open-button {
  display: none;
}

.store-stand-container .store-stand-body {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.store-stand-container .store-stand-body .delivery-price-container {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-stand-container .store-stand-body .delivery-price-content {
  position: relative;
  border-radius: 1rem;
  width: 900px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.store-stand-container .store-stand-body .delivery-price-content img {
  width: 100%;
}

.store-stand-container .store-stand-body .delivery-price-header,
.store-stand-container .store-stand-body .delivery-price-footer {
  position: absolute;
  width: 100%;
  display: flex;
  color: #f7b614;
  height: 5%;
  align-items: center;
}

.store-stand-container .store-stand-body .delivery-price-footer {
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 700;
  bottom: 0;
}

.store-stand-container .store-stand-body .delivery-price-header {
  justify-content: flex-end;
  padding-right: 2rem;
  padding-top: 2rem;
  font-size: 1.8rem;
  font-weight: 700;
}

.store-stand-container .store-stand-body .delivery-price-header p {
  transition: ease-in-out .2s;
  cursor: pointer;
}

.store-stand-container .store-stand-body .delivery-price-header p:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.store-stand-container .store-stand-body .store-stand-body-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.store-stand-container .store-stand-body .store-stand-body-content #open-shopping-cart,
.store-stand-container .store-stand-body .store-stand-body-content .open-shopping-cart-icon,
.store-stand-container .store-stand-body .store-stand-body-content .close-shopping-cart,
.store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu,
.store-stand-container .store-stand-body .store-stand-body-content .open-aside-menu-icon,
.store-stand-container .store-stand-body .store-stand-body-content .close-aside-menu {
  display: none;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu,
.store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
  width: 22%;
  height: 100%;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
  padding: 5px;
  background: white;
  background: linear-gradient(90deg, white 0%, white 50%, #d6d6d6 100%);
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu {
  display: flex;
  flex-direction: column;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu .aside-menu-container {
  background-image: none;
  background: white;
  background: linear-gradient(90deg, white 0%, #d6d6d6 0%, white 50%);
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu .store-stand-menu-container {
  position: -webkit-sticky;
  position: sticky;
  top: 7rem;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body {
  display: flex;
  flex-direction: column;
  width: 55%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-cards .store-stand-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 20%;
  margin-left: 24px;
  color: #808080;
  height: 3rem;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-cards .store-stand-card p {
  cursor: pointer;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-cards .underline {
  text-decoration: underline;
  -webkit-text-decoration-color: #f7b614;
          text-decoration-color: #f7b614;
  text-decoration-thickness: 3px;
  color: #32343a;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-label {
  width: 100%;
  min-height: 3rem;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #808080;
  margin: 0 1rem 1rem 0;
  font-size: 1.2rem;
  font-weight: 400;
  color: #808080;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 1400px) {
  .store-stand-container .store-stand-header .store-stand-info {
    height: 35rem;
  }
}

@media screen and (min-width: 2200px) {
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
    width: 22%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body {
    width: 55%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container .store-stand-display-body-content {
    width: 20%;
  }
}

@media screen and (min-width: 1600px) and (max-width: 2200px) {
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
    width: 22%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body {
    width: 55%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container .store-stand-display-body-content {
    width: 25%;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
    width: 22%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body {
    width: 55%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container .store-stand-display-body-content {
    width: 33.33%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
    width: 27%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body {
    width: 50%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container .store-stand-display-body-content {
    width: 50%;
  }
}

@media screen and (min-width: 1800px) {
  .store-stand-informations-container .address-and-work-hours .address-location {
    width: 28rem;
  }
  .store-stand-informations-container .address-and-work-hours .work-days {
    width: 22rem;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1800px) {
  .store-stand-informations-container .address-and-work-hours {
    flex-direction: column;
  }
  .store-stand-informations-container .address-and-work-hours .work-days {
    margin-bottom: 3rem;
    width: 30rem;
  }
  .store-stand-informations-container .address-and-work-hours .address-location {
    width: 30rem;
    height: 20rem;
  }
}

@media screen and (max-width: 1100px) {
  .store-stand-informations-container .address-and-work-hours {
    padding: 0 2rem;
  }
  .store-stand-informations-container .informations {
    padding: 0 2rem;
  }
  .store-stand-container .store-stand-header .store-stand-info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    min-width: 100%;
    height: 65vmin;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content {
    padding: .5rem .5rem;
    min-width: 100%;
    border-radius: 0;
    flex-direction: column;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head {
    flex-direction: column;
    width: 100%;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name {
    width: 100%;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name .name-rating {
    display: flex;
    justify-content: space-between;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name .name {
    font-size: 6vmin;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name .store-name-location-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-name .info {
    font-size: 3.5vmin;
    margin-top: .7vmin;
    margin-bottom: .7vmin;
    color: #585858;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 3.5vmin;
    width: 100%;
    color: #585858;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours .store-work-hours,
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours .delivery-work-hours {
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours .store-work-hours .info,
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours .delivery-work-hours .info {
    margin-right: 1.5vmin;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .work-hours .delivery-work-hours {
    justify-content: flex-end;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-rating {
    display: flex;
    align-items: center;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-rating .rating {
    font-size: 5vmin;
    color: #585858;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-head .store-rating img {
    max-width: 5vmin;
    max-height: auto;
    margin-right: 2vmin;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .menu-open-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30vmin;
    font-size: 5vmin;
    font-weight: 800;
    color: #f7b614;
    background-color: rgba(245, 240, 240, 0.7);
    border: 0;
    outline: 0;
    border-radius: 10px;
    padding: .5vmin 1vmin;
    height: 8vmin;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .menu-open-button img {
    height: 5vmin;
    width: 5vmin;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .store-stand-info-body-icons {
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .store-stand-info-body-icons p {
    font-size: 4vmin;
    color: #585858;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body img {
    max-width: 7vmin;
    max-height: auto;
    margin-right: .5rem;
    margin-left: .8rem;
  }
  .store-stand-container .store-stand-body .store-stand-body-content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
    padding: 0;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-aside-menu-icon {
    display: flex;
    position: fixed;
    bottom: 2vmin;
    left: 20vmin;
    z-index: 20;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-aside-menu-icon i {
    width: 15vmin;
    height: 15vmin;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f7b614;
    border-radius: 50%;
    box-shadow: 6px 10px 30px 12px rgba(82, 82, 82, 0.3);
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-aside-menu-icon i .aside-menu {
    width: 60%;
    height: 60%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-shopping-cart-icon {
    display: flex;
    position: fixed;
    bottom: 2vmin;
    right: 20vmin;
    z-index: 20;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-shopping-cart-icon i {
    width: 15vmin;
    height: 15vmin;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f7b614;
    border-radius: 50%;
    box-shadow: 6px 10px 30px 12px rgba(82, 82, 82, 0.3);
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-shopping-cart-icon i .shopping-cart {
    width: 60%;
    height: 60%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-shopping-cart-icon .shopping-cart-item-number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20%;
    left: 55%;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    width: 5vmin;
    height: 5vmin;
    border: 1px solid red;
    font-size: 3vmin;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .slide-left {
    -webkit-animation: slide-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
    animation: slide-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
  }
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-7rem);
              transform: translateX(-7rem);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-7rem);
              transform: translateX(-7rem);
    }
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-shopping-cart:checked ~ .open-shopping-cart-icon {
    display: none;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-shopping-cart:checked ~ .close-shopping-cart {
    display: flex;
    position: fixed;
    top: 6.6rem;
    right: -4%;
    font-size: 4vmin;
    width: 2rem;
    height: 2rem;
    z-index: 24;
    color: white;
    font-size: 8vmin;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-shopping-cart:checked ~ .store-stand-shopping-cart-container {
    right: 0;
    transition: ease-in-out .5s;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .open-aside-menu-icon {
    display: none;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    display: flex;
    position: fixed;
    top: 6.6rem;
    right: -4%;
    width: 2rem;
    height: 2rem;
    color: white;
    font-size: 8vmin;
    z-index: 24;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .store-stand-side-menu {
    left: 0;
    transition: ease-in-out .5s;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu {
    width: 100%;
    position: fixed;
    top: 2rem;
    left: -100%;
    height: 100%;
    transition: ease-in-out .5s;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 23;
    align-items: center;
    justify-content: center;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu .aside-menu-container {
    height: 100%;
    border: 0;
    padding: 0;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container {
    width: 100%;
    position: fixed;
    top: 2rem;
    right: -100%;
    height: 100%;
    transition: ease-in-out .5s;
    z-index: 23;
    background: none;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-shopping-cart-container .shopping-cart-container {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    border: 0;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu {
    width: 100%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu .aside-menu-container {
    flex-direction: row;
    background-image: none;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-side-menu .aside-menu-container .hidden-store-stand {
    display: none;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body {
    flex-direction: column;
    width: 100%;
    overflow-y: visible;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-cards {
    justify-content: space-between;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-cards .store-stand-card p {
    font-size: 4vmin;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-label p {
    margin-left: 2rem;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container {
    overflow: scroll;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-wrap: nowrap;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-container::-webkit-scrollbar {
    display: none;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .store-stand-display-body .store-stand-display-body-content {
    height: 20rem;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1800px) {
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content {
    width: 70%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1250px) {
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content {
    width: 85%;
  }
}

@media screen and (min-width: 1100px) {
  .store-stand-container .store-stand-body .store-stand-menu-container .unselected-content,
  .store-stand-container .store-stand-body .store-stand-menu-container .menu-selected {
    margin-top: 1%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .store-stand-informations-container .address-and-work-hours .work-days {
    width: 25rem;
    margin-left: 3rem;
  }
  .store-stand-informations-container .address-and-work-hours .address-location {
    width: 25rem;
    margin-right: 3rem;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .store-stand-informations-container .address-and-work-hours .work-days {
    width: 25rem;
    margin-left: 0rem;
  }
  .store-stand-informations-container .address-and-work-hours .address-location {
    width: 25rem;
    margin-right: 0rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .store-stand-informations-container .address-and-work-hours .work-days {
    width: 20rem;
  }
  .store-stand-informations-container .address-and-work-hours .address-location {
    width: 20rem;
  }
}

@media screen and (max-width: 768px) {
  .store-stand-container .store-stand-body .delivery-price-content {
    border-radius: 0;
  }
  .store-stand-container .store-stand-body .delivery-price-header {
    font-size: 5vmin;
    padding-top: 5vmin;
    padding-right: 5vmin;
  }
  .store-stand-container .store-stand-body .delivery-price-footer {
    font-size: 2vmin;
    padding-top: 2vmin;
    padding-bottom: 5vmin;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-shopping-cart:checked ~ .close-shopping-cart {
    top: 6.6rem;
    right: -10%;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    top: 6.6rem;
    right: -8%;
  }
}

@media screen and (max-width: 830px) {
  .store-stand-informations-container .address-and-work-hours {
    flex-direction: column;
    height: 35rem;
  }
  .store-stand-informations-container .address-and-work-hours .work-days {
    width: 20rem;
  }
  .store-stand-informations-container .address-and-work-hours .store-stand-combobox {
    padding-left: 0;
  }
}

@media screen and (max-width: 670px) {
  .store-stand-informations-container .address-and-work-hours {
    flex-direction: column;
    height: 35rem;
  }
  .store-stand-informations-container .address-and-work-hours .work-days {
    width: 20rem;
  }
  .store-stand-informations-container .address-and-work-hours .store-stand-combobox {
    padding-left: 0;
  }
  .store-stand-informations-container .informations {
    padding: 0 2rem;
  }
  .store-stand-informations-container .rating-group {
    display: none;
  }
  .store-stand-container .store-stand-header .store-stand-info .add-to-favorite {
    position: absolute;
    top: 2rem;
  }
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-not-added,
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-added {
    top: 2rem;
  }
  .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    top: 6rem;
  }
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-6rem);
              transform: translateX(-6rem);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-6rem);
              transform: translateX(-6rem);
    }
  }
}

@media screen and (max-width: 600px) {
  .store-stand-container .store-stand-body .store-stand-body-content .close-shopping-cart .shopping-cart {
    font-size: 10vmin;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    right: -11%;
  }
}

@media screen and (max-width: 520px) {
  .store-stand-container .store-stand-header .store-stand-info .add-to-favorite {
    width: 2.5rem;
    height: 2.5rem;
  }
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-not-added,
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-added {
    top: 1.5rem;
  }
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-5.2rem);
              transform: translateX(-5.2rem);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-5.2rem);
              transform: translateX(-5.2rem);
    }
  }
}

@media screen and (max-width: 450px) {
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    right: -14%;
  }
}

@media screen and (max-width: 430px) {
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    top: 5.7rem;
  }
}

@media screen and (max-width: 430px) {
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-4.8rem);
              transform: translateX(-4.8rem);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-4.8rem);
              transform: translateX(-4.8rem);
    }
  }
}

@media screen and (max-width: 385px) {
  .store-stand-container .store-stand-header .store-stand-info .add-to-favorite {
    top: 1.5rem;
  }
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-not-added,
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-added {
    top: 1rem;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    right: -16%;
  }
}

@media screen and (max-width: 360px) {
  .store-stand-informations-container .address-and-work-hours .work-days,
  .store-stand-informations-container .address-and-work-hours .address-location {
    width: 17rem;
  }
  .store-stand-informations-container .address-and-work-hours .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .store-stand-informations-container .address-and-work-hours .address form {
    flex-direction: column;
    align-items: center;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .menu-open-button {
    width: 30vmin;
    font-size: 5vmin;
    margin-bottom: -2vmin;
    border-radius: 5px;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .menu-open-button i {
    display: flex;
    align-items: center;
  }
  .store-stand-container .store-stand-header .store-stand-info .store-stand-info-content .store-stand-info-body .menu-open-button i img {
    height: 6vmin;
    width: 6vmin;
  }
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-3.9rem);
              transform: translateX(-3.9rem);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-3.9rem);
              transform: translateX(-3.9rem);
    }
  }
}

@media screen and (max-width: 360px) {
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    right: -15%;
  }
}

@media screen and (max-width: 340px) {
  .store-stand-container .store-stand-header .store-stand-info .add-to-favorite {
    top: 1rem;
  }
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-not-added,
  .store-stand-container .store-stand-header .store-stand-info .tooltip .tooltiptext-added {
    top: .5rem;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-shopping-cart:checked ~ .close-shopping-cart {
    top: 6rem;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    top: 5.5rem;
  }
}

@media screen and (max-width: 330px) {
  .store-stand-container .store-stand-body .store-stand-body-content #open-aside-menu:checked ~ .close-aside-menu {
    right: -17%;
    top: 5.3rem;
  }
}

@media screen and (max-width: 1100px) and (min-width: 768px) and (max-height: 1600px) {
  .store-stand-container .store-stand-body .store-stand-body-content .open-shopping-cart-icon i,
  .store-stand-container .store-stand-body .store-stand-body-content .open-aside-menu-icon i {
    width: 9vmin;
    height: 9vmin;
  }
  .store-stand-container .store-stand-body .store-stand-body-content .open-shopping-cart-icon .shopping-cart-item-number,
  .store-stand-container .store-stand-body .store-stand-body-content .open-aside-menu-icon .shopping-cart-item-number {
    font-size: 2vmin;
    width: 3vmin;
    height: 3vmin;
  }
  .store-stand-container .store-stand-body .store-stand-body-content #open-shopping-cart:checked ~ .close-shopping-cart {
    font-size: 5vmin;
  }
}
/*# sourceMappingURL=StoreStand.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.site-footer {
  max-width: 2580px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed lightGray;
  height: 3rem;
  background-color: #32343a;
  margin-left: auto;
  margin-right: auto;
}

.site-footer p {
  color: white;
  font-style: italic;
}

.site-footer .mango {
  color: #f7b614;
}

.site-footer a {
  text-decoration: none;
  color: #f7b614;
}

@media screen and (max-width: 500px) {
  .site-footer p {
    font-size: 15px;
  }
}

@media screen and (max-width: 450px) {
  .site-footer {
    border-top: 1px solid lightGray;
  }
  .site-footer p {
    font-size: 10px;
  }
}
/*# sourceMappingURL=Footer.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.my-profile-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: white;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
}

.my-profile-container .my-profile-aside-menu {
  min-width: 25%;
}

.my-profile-container .notification-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.my-profile-container #sound-off,
.my-profile-container #notification-off {
  display: none;
}

.my-profile-container .slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.my-profile-container .custom-slider {
  width: 4.5rem;
  height: 1.7rem;
  background-color: #808080;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: .9rem;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: .5rem;
  transition: ease-in-out .5s;
  cursor: pointer;
  margin-left: 1rem;
}

.my-profile-container .custom-slider span {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background-color: white;
  color: white;
  transition: .5s;
}

.my-profile-container #sound-off:checked ~ .custom-slider,
.my-profile-container #notification-off:checked ~ .custom-slider {
  background-color: #f7b614;
}

.my-profile-container #sound-off:checked ~ .custom-slider span,
.my-profile-container #notification-off:checked ~ .custom-slider span {
  -webkit-transform: translateX(43px);
          transform: translateX(43px);
}

.my-profile-container .my-profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  width: 80%;
}

.my-profile-container .my-profile-content h1 {
  margin-bottom: 2rem;
  color: #808080;
}

.my-profile-container .my-profile-content form input {
  width: 90%;
}

.my-profile-container .my-profile-content .my-profile-info {
  width: 40rem;
  height: 40rem;
  text-align: center;
  background-image: url(../../static/media/infoback.97966925.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.my-profile-container .my-profile-content .my-profile-info .my-profile-info-content {
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.my-profile-container .my-profile-content .my-profile-info p {
  font-size: 1rem;
  color: #808080;
  cursor: auto;
}

.my-profile-container .my-profile-content .my-profile-info h1 {
  color: #f7b614;
  margin-bottom: 0.5rem;
  cursor: auto;
}

.my-profile-container .my-profile-content .my-profile-info i {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.my-profile-container .my-profile-content .my-profile-info i img {
  width: 2rem;
  height: 2rem;
}

.my-profile-container .my-profile-content .my-profile-info .my-profile-make-changes-button {
  background: transparent;
  border: 0;
  outline: 0;
}

.my-profile-container .my-profile-content .my-profile-info .my-profile-make-changes-button p {
  font-size: .8rem;
}

.my-profile-container .my-profile-content .my-profile-info .orders-and-points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.my-profile-container .my-profile-content .my-profile-info .info-total-orders {
  border: 0.9rem solid #f7b614;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50%;
  width: 9rem;
  height: 9rem;
  padding: .5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.my-profile-container .my-profile-content .my-profile-info .info-total-orders h1 {
  margin: 0;
  font-size: 3rem;
  color: #f7b614;
}

.my-profile-container .my-profile-content .my-profile-orders-filter {
  width: 100%;
  display: flex;
  margin-bottom: 0;
  margin-top: 1rem;
}

.my-profile-container .my-profile-content .my-profile-orders-filter .order-filer-div {
  height: 2rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid #f7b614;
  cursor: pointer;
  color: #585858;
  font-weight: 700;
}

.my-profile-container .my-profile-content .my-profile-orders-filter .unselected-filter {
  background-color: #dadada;
  border-radius: 1rem 1rem 0 0;
}

.my-profile-container .my-profile-content .my-profile-orders-filter .selected-filter {
  border-top: 3px solid #f7b614;
  border-left: 3px solid #f7b614;
  border-right: 3px solid #f7b614;
  border-bottom: 0;
  border-radius: 1rem 1rem 0 0;
}

.my-profile-container .my-profile-content .my-profile-title {
  border-bottom: 2px dotted #f7b614;
  margin-bottom: 1rem;
}

.my-profile-container .my-profile-content .my-profile-address,
.my-profile-container .my-profile-content .my-profile-favorite-store,
.my-profile-container .my-profile-content .my-profile-orders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 2rem 0;
  overflow-y: scroll;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
  transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column:hover .my-address-size,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column:hover .my-favorite-size,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column:hover .my-order-size,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column:hover .my-address-size,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column:hover .my-favorite-size,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column:hover .my-order-size,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover .my-address-size,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover .my-favorite-size,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover .my-order-size {
  box-shadow: 10px 10px 5px 0px rgba(128, 128, 128, 0.5);
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column:hover .delete:hover,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column:hover .delete:hover,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover .delete:hover {
  color: red;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column:hover .update:hover,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column:hover .update:hover,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover .update:hover {
  color: #06c006;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column:hover .my-profile-button,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column:hover .my-profile-button,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover .my-profile-button {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0;
  outline: 0;
  background-color: white;
  color: #808080;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column:hover .my-profile-button img,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column:hover .my-profile-button img,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover .my-profile-button img {
  width: 24px;
  height: 24px;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-address-size,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-address-size,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-address-size {
  width: 10rem;
  min-height: 15rem;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-favorite-size,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-favorite-size,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-favorite-size {
  width: 10.5rem;
  height: 15rem;
  cursor: pointer;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-order-size,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-order-size,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-order-size {
  width: 15rem;
  height: 18rem;
  margin-top: 1rem;
  cursor: pointer;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display {
  background-color: white;
  border: 2px solid rgba(128, 128, 128, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  border-radius: 10px;
  overflow: hidden;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .my-profile-my-order,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .my-profile-my-order,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .my-profile-my-order {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  color: #585858;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .cancel-reason-container,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .cancel-reason-container,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .cancel-reason-container {
  width: 100%;
  padding: .5rem;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .cancel-reason-container .cancel-title,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .cancel-reason-container .cancel-title,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .cancel-reason-container .cancel-title {
  text-align: center;
  margin-bottom: .5rem;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .my-order-items,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .my-order-items,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .my-order-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  color: #585858;
  padding: 1%;
  word-break: break-word;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .order-button,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .order-button,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .order-button {
  background-color: #f7b614;
  border: 0;
  font-size: 1rem;
  padding: .5rem .2rem;
  border-radius: 5px;
  outline: 0;
  color: #32343a;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .order-button:active,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .order-button:active,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .order-button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .order-status,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .order-status,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .order-status {
  width: 120%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -.5rem;
  margin-top: .3rem;
  color: white;
  font-weight: 600;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .pending-for-delivery,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .pending-for-delivery,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .pending-for-delivery {
  background-color: #f7b614;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .pending-for-client,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .pending-for-client,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .pending-for-client {
  background-color: #12555d;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .done,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .done,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .done {
  background-color: #377e56;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .canceled,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .canceled,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .canceled {
  background-color: #d40707;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .in-progress,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .in-progress,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .in-progress {
  background-color: #585858;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .store-name,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .store-name,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .store-name {
  color: #585858;
  text-align: center;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .my-order-items,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .my-order-items,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .my-order-items {
  width: 100%;
  height: 70%;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-order-display .my-order-items .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .cart-item-name,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-order-display .my-order-items .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .cart-item-name,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-order-display .my-order-items .shopping-cart-item-container .shopping-cart-item-info .single-cart-item-info-header .cart-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display {
  background-color: #e5e2e2;
  border: 2px solid rgba(128, 128, 128, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  border-radius: 10px;
  overflow: hidden;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .image-div,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .image-div,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .image-div {
  width: 100%;
  height: 7rem;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .image-div .address-img,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .image-div .address-img,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .image-div .address-img {
  max-width: 100%;
  max-height: 100%;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .image-div .store-img,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .image-div .store-img,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .image-div .store-img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div h4,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div h4,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div h4 {
  color: #f7b614;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div h5,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div p,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div h5,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div p,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div h5,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div p {
  color: #585858;
  margin-top: 2px;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div p,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div p,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div p {
  font-size: .89rem;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div span,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div span,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div span {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div span img,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div span img,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div span img {
  width: 24px;
  height: 24px;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div .down-span,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div .up-span,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div .favorite-store,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div .down-span,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div .up-span,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div .favorite-store,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div .down-span,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div .up-span,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div .favorite-store {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div .down-span img,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div .up-span img,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div .favorite-store img,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div .down-span img,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div .up-span img,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div .favorite-store img,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div .down-span img,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div .up-span img,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div .favorite-store img {
  width: 20px;
  height: 20px;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div .up-span p,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div .up-span p,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div .up-span p {
  color: #f7b614;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .couried-delivery-container,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .couried-delivery-container,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .couried-delivery-container {
  width: 100%;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .couried-delivery,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .couried-delivery,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .couried-delivery {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1%;
  text-align: left;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .couried-delivery-sender,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .couried-delivery-receiver,
.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .couried-delivery-comment,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .couried-delivery-sender,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .couried-delivery-receiver,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .couried-delivery-comment,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .couried-delivery-sender,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .couried-delivery-receiver,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .couried-delivery-comment {
  font-weight: 400;
  color: #585858;
  margin-bottom: 1rem;
  width: 100%;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .bold,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .bold,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .bold {
  font-weight: 600;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-footer,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-footer,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 1rem 0;
  height: 3rem;
}

.my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-button,
.my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-button,
.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-button {
  display: none;
}

.my-profile-container .my-profile-content .my-profile-orders {
  border-left: 3px solid #f7b614;
  border-right: 3px solid #f7b614;
  border-bottom: 3px solid #f7b614;
  border-radius: 0 0 1rem 1rem;
  height: 75%;
  overflow-x: hidden;
}

.my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
  width: 20%;
}

@media screen and (min-width: 2000px) and (max-width: 2580px) {
  .my-profile-container .my-profile-aside-menu {
    min-width: 20%;
  }
}

@media screen and (min-width: 1450px) and (max-width: 1900px) {
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
    width: 25%;
  }
}

@media screen and (min-width: 1400px) {
  .my-profile-container .my-profile-content .my-profile-info {
    width: 50rem;
    height: 50rem;
  }
  .my-profile-container .my-profile-content .my-profile-info p {
    font-size: 2rem;
  }
  .my-profile-container .my-profile-content .my-profile-info h1 {
    font-size: 3rem;
  }
  .my-profile-container .my-profile-content .my-profile-info i {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .my-profile-container .my-profile-content .my-profile-info i img {
    width: 3rem;
    height: 3rem;
  }
  .my-profile-container .my-profile-content .my-profile-info .my-profile-make-changes-button p {
    font-size: 1rem;
  }
  .my-profile-container .my-profile-content .my-profile-info .info-total-orders h1 {
    margin: 0;
    font-size: 4rem;
    color: #f7b614;
  }
}

@media screen and (min-width: 2100px) {
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 14.28%;
  }
}

@media screen and (min-width: 1750px) and (max-width: 2100px) {
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 16.66%;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1750px) {
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 20%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px) {
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 25%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .my-profile-container .my-profile-content {
    padding: 1rem 0;
  }
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 33.33%;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .my-profile-container .my-profile-content {
    padding: 1rem 0;
  }
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 50%;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .my-profile-container .my-profile-content {
    padding: 1rem 0;
  }
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 33.33%;
  }
}

@media screen and (min-width: 400px) and (max-width: 600px) {
  .my-profile-container .my-profile-content {
    padding: 1rem 0;
  }
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column {
    width: 50%;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1450px) {
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
    width: 33.33%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
    width: 50%;
  }
}

@media screen and (max-width: 1065px) {
  .my-profile-container .my-profile-aside-menu {
    min-width: 30%;
  }
  .my-profile-container .my-profile-content {
    min-width: 70%;
  }
}

@media screen and (max-width: 915px) {
  .my-profile-container .my-profile-aside-menu {
    min-width: 33%;
  }
  .my-profile-container .my-profile-content {
    min-width: 67%;
  }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
  .my-profile-container .my-profile-content .my-profile-info {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 850px) {
  .my-profile-container .my-profile-aside-menu {
    min-width: 36%;
  }
  .my-profile-container .my-profile-content {
    min-width: 64%;
  }
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .my-profile-container {
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 5rem;
  }
  .my-profile-container .aside-menu-container {
    background-image: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
  }
  .my-profile-container .aside-menu-food-type {
    flex-direction: row;
    justify-content: space-evenly;
    background-color: white;
    margin-top: 0;
  }
  .my-profile-container .aside-menu-food-type .menu-filter-container {
    flex-direction: column-reverse;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    opacity: .5;
    align-items: center;
    justify-content: center;
  }
  .my-profile-container .aside-menu-food-type .menu-filter-container .font-awesome {
    display: flex;
  }
  .my-profile-container .aside-menu-food-type .menu-filter-container .flat-icon {
    display: none;
  }
  .my-profile-container .aside-menu-food-type .menu-filter-container i {
    width: 12vmin;
    height: 12vmin;
    box-shadow: 10px 10px 16px -9px rgba(0, 0, 0, 0.75);
    border-radius: 2vmin;
    margin-bottom: .5rem;
    font-size: 6vmin;
    margin-right: 0;
  }
  .my-profile-container .aside-menu-food-type .menu-filter-container img {
    width: 80%;
    height: 80%;
  }
  .my-profile-container .aside-menu-user-name {
    display: none;
  }
  .my-profile-container .selected {
    flex-direction: column-reverse;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: white;
    font-weight: 600;
    color: #f7b614;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    text-align: center;
  }
  .my-profile-container .selected:hover {
    cursor: pointer;
    background-color: white;
  }
  .my-profile-container .selected .flat-icon {
    display: none;
  }
  .my-profile-container .selected .font-awesome {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .my-profile-container .selected i {
    width: 12vmin;
    height: 12vmin;
    background-color: #32343a;
    border-radius: 2vmin;
    margin-bottom: .5rem;
    color: #f7b614;
    font-size: 6vmin;
    margin-right: 0;
  }
  .my-profile-container .selected img {
    width: 80%;
    height: 80%;
  }
  .my-profile-container .my-profile-content {
    width: 100%;
    padding: .5rem .5rem;
  }
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
    width: 50%;
  }
  .my-profile-container .my-profile-content .my-profile-info {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .my-profile-container .my-profile-aside-menu {
    min-width: 100%;
  }
  .my-profile-container .my-profile-content .custom-slider {
    margin-left: -2rem;
  }
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column:hover {
    -webkit-transform: none;
            transform: none;
  }
}

@media screen and (max-width: 670px) {
  .my-profile-container {
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .my-profile-container .aside-menu-food-type .selected {
    flex-direction: column-reverse;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 3rem;
    background-color: white;
    font-weight: 600;
    color: #f7b614;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .my-profile-container .aside-menu-food-type .selected i {
    margin-right: 0;
  }
  .my-profile-container .aside-menu-food-type .selected p {
    font-size: 3vmin;
  }
  .my-profile-container .aside-menu-food-type .menu-filter-container p {
    font-size: 3vmin;
  }
  .my-profile-container .aside-menu-food-type .menu-filter-container img {
    width: 80%;
    height: 80%;
  }
  .my-profile-container .my-profile-content {
    width: 100%;
    padding: .5rem .5rem;
  }
  .my-profile-container .my-profile-content .my-profile-info {
    width: 100%;
  }
}

@media screen and (max-width: 590px) {
  .my-profile-container .my-profile-content h1 {
    word-wrap: break-word;
  }
}

@media screen and (max-width: 580px) {
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-order-size,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-order-size {
    width: 40vmin;
    height: 20rem;
    margin-left: .2rem;
  }
}

@media screen and (max-width: 520px) {
  .my-profile-container .my-profile-content .notification-container p {
    font-size: .8rem;
  }
  .my-profile-container .my-profile-content .my-profile-info .orders-and-points p {
    font-size: .8rem;
  }
  .my-profile-container .my-profile-content .my-profile-info .info-total-orders {
    width: 7rem;
    height: 7rem;
  }
  .my-profile-container .my-profile-content .my-profile-info .info-total-orders h1 {
    font-size: 2.5rem;
  }
  .my-profile-container .my-profile-content form input {
    font-size: 1.2rem;
  }
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column {
    width: 100%;
  }
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-order-size {
    width: 80%;
    height: 20rem;
  }
}

@media screen and (max-width: 390px) {
  .my-profile-container {
    margin-top: 5rem;
  }
  .my-profile-container .my-profile-content .my-profile-orders-filter .order-filer-div {
    font-size: .8rem;
  }
  .my-profile-container .my-profile-content h1 {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  .my-profile-container .my-profile-content .my-profile-address,
  .my-profile-container .my-profile-content .my-profile-favorite-store,
  .my-profile-container .my-profile-content .my-profile-orders {
    flex-direction: column;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-address-size,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-favorite-size,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-address-size,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-favorite-size,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-address-size,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-favorite-size {
    width: 15rem;
    height: 20rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .image-div,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .image-div,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .image-div {
    height: 10rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div h4,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div h4,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div h4 {
    font-size: 1.5rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div h5,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div h5,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div h5 {
    font-size: 1.2rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div p,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div p,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .my-profile-container .my-profile-content h1 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 800px) {
  .my-profile-container .my-profile-content .my-profile-info {
    width: 100%;
    height: 90%;
  }
}

@media screen and (min-width: 800px) and (max-height: 1200px) {
  .my-profile-container .custom-slider {
    width: 4rem;
    height: 1.3rem;
  }
  .my-profile-container .custom-slider span {
    width: .9rem;
    height: .9rem;
  }
  .my-profile-container .my-profile-content {
    padding: 0 1rem;
  }
  .my-profile-container .my-profile-content .my-profile-info .my-profile-info-content {
    justify-content: flex-start;
    padding-top: 5vmin;
  }
  .my-profile-container .my-profile-content .my-profile-info .my-profile-info-content div {
    margin-top: 1vmin;
    margin-bottom: 1vmin;
  }
  .my-profile-container .my-profile-content .my-profile-info p {
    font-size: 2.5vmin;
  }
  .my-profile-container .my-profile-content .my-profile-info i img {
    width: 3vmin;
    height: 3vmin;
  }
  .my-profile-container .my-profile-content .my-profile-info .my-profile-make-changes-button p {
    font-size: .6rem;
  }
  .my-profile-container .my-profile-content .my-profile-info h1 {
    font-size: 3vmin;
  }
  .my-profile-container .my-profile-content .my-profile-info .info-total-orders {
    width: 9vmin;
    height: 9vmin;
    padding: .5rem;
    border-width: 1vmin;
  }
  .my-profile-container .my-profile-content .my-profile-info .info-total-orders h1 {
    font-size: 3.5vmin;
  }
}

@media screen and (max-width: 390px) {
  .my-profile-container {
    margin-top: 5rem;
  }
  .my-profile-container .my-profile-content .my-profile-orders-filter .order-filer-div {
    font-size: .8rem;
  }
  .my-profile-container .my-profile-content h1 {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  .my-profile-container .my-profile-content .my-profile-address,
  .my-profile-container .my-profile-content .my-profile-favorite-store,
  .my-profile-container .my-profile-content .my-profile-orders {
    flex-direction: column;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-address-size,
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-favorite-size,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-address-size,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-favorite-size,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-address-size,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-favorite-size {
    width: 15rem;
    height: 20rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .image-div,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .image-div,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .image-div {
    height: 10rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div h4,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div h4,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div h4 {
    font-size: 1.5rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div h5,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div h5,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div h5 {
    font-size: 1.2rem;
  }
  .my-profile-container .my-profile-content .my-profile-address .my-profile-box-column .my-profile-single-display .info-div p,
  .my-profile-container .my-profile-content .my-profile-favorite-store .my-profile-box-column .my-profile-single-display .info-div p,
  .my-profile-container .my-profile-content .my-profile-orders .my-profile-box-column .my-profile-single-display .info-div p {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=MyProfile.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.input-field-container {
  display: flex;
  flex-direction: column;
}

.input-field-container input {
  font-size: 2rem;
  background-color: transparent;
  border: 2px solid #585858;
  border-radius: 5px;
  color: #585858;
  text-align: center;
}

.input-field-container input:focus {
  outline: none !important;
  border: 2px solid #f7b614;
}

.input-field-container .input-field-button-container {
  display: flex;
  justify-content: space-around;
  width: 100;
  margin-top: 1rem;
}

.input-field-container .input-field-button-container .input-field-cancel,
.input-field-container .input-field-button-container .input-field-confirm {
  width: 2rem;
  height: 2rem;
  border: 0;
  outline: 0;
  background: transparent;
}

.input-field-container .input-field-button-container .input-field-cancel:active,
.input-field-container .input-field-button-container .input-field-confirm:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.input-field-container .input-field-button-container .input-field-cancel {
  background-image: url(../../static/media/quit.7e5aec8d.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.input-field-container .input-field-button-container .input-field-cancel:hover {
  background-image: url(../../static/media/quit-red.eb227c31.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.input-field-container .input-field-button-container .input-field-confirm {
  background-image: url(../../static/media/tick.b3f17b79.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.input-field-container .input-field-button-container .input-field-confirm:hover {
  background-image: url(../../static/media/tick-green.16d7be14.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}
/*# sourceMappingURL=InputField.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.order-info-container {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-info-container .order-info-content {
  width: 700px;
  height: 700px;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.1rem;
}

.order-info-container .order-info-footer {
  position: relative;
  padding: .5rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  color: #32343a;
  font-size: 1.3rem;
  font-weight: 700;
  min-height: 3.3rem;
}

.order-info-container .order-info-footer p,
.order-info-container .order-info-footer button {
  z-index: 9;
}

.order-info-container .order-info-footer::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7b614;
  border-radius: 50% 50% 0 0 / 50% 50% 0 0;
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  z-index: 9;
}

.order-info-container .order-info-header {
  position: relative;
  padding: .5rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  color: #32343a;
  font-size: 1.3rem;
  font-weight: 700;
  height: 3.3rem;
}

.order-info-container .order-info-header p {
  z-index: 9;
}

.order-info-container .order-info-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7b614;
  border-radius: 0 0 50% 50%/0 0 50% 50%;
  -webkit-transform: scaleX(1.5);
          transform: scaleX(1.5);
  z-index: 9;
}

.order-info-container .order-info-body {
  display: flex;
  width: 100%;
  height: 75%;
}

.order-info-container .order-left,
.order-info-container .order-right {
  width: 50%;
  height: 100%;
  padding: .5rem;
  color: #808080;
  overflow-y: scroll;
}

.order-info-container .order-left {
  overflow-y: scroll;
}

.order-info-container .order-left .shopping-cart-item-container {
  height: 4rem;
}

.order-info-container .receiver-info {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}

.order-info-container .receiver-info img {
  width: 3rem;
}

.order-info-container .order-number {
  padding-bottom: 1rem;
  color: #808080;
}

.order-info-container .address {
  padding-left: 1rem;
}

.order-info-container .btn-style-white {
  border: 0;
  background: white;
  text-align: center;
  padding: 7px 30px;
  outline: none;
  color: #32343a;
  border: 2px solid white;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0px 6px 5px -2px #525252;
}

.order-info-container .btn-style-white:hover {
  border: 2px solid #32343a;
}

.order-info-container .price-info {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.order-info-container .price-info p {
  margin-top: .5rem;
}

.order-info-container .total {
  font-weight: 600;
}

.order-info-container .order-canceled,
.order-info-container .leave-feedback {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.order-info-container .leave-feedback-btn,
.order-info-container .repeat-order-btn {
  width: 90%;
  padding: .8rem;
  background-color: #32343a;
  outline: none;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border-radius: .5rem;
}

.order-info-container .repeat-order-btn {
  background-color: #377e56;
}

.order-info-container .leave-feedback,
.order-info-container .repeat-order {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-info-container .courier-delivery-sender p,
.order-info-container .courier-delivery-receiver p,
.order-info-container .courier-delivery-comment p {
  margin-top: .5rem;
}

.order-info-container .bold {
  font-weight: bold;
}

.order-info-container .space-between {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-height: 700px) {
  .order-info-container .order-info-content {
    height: 90%;
  }
}

@media screen and (max-width: 750px) {
  .order-info-container .order-info-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@media screen and (max-width: 600px) {
  .order-info-container .receiver-info img {
    display: none;
  }
  .order-info-container .address {
    padding-left: 0;
  }
}

@media screen and (max-width: 360px) {
  .order-info-container .shopping-cart-item-container .shopping-cart-item-image {
    display: none;
  }
  .order-info-container .shopping-cart-item-container .shopping-cart-item-info {
    width: 100%;
  }
}
/*# sourceMappingURL=OrderInfoMadal.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.feedback-container {
  width: 100%;
  height: 100%;
}

.feedback-container > div {
  margin-top: 1rem;
}

.feedback-container textarea {
  width: 100%;
  border-radius: .2rem;
  outline-color: #f7b614;
  padding: .5rem;
}

.feedback-container input[type='radio'] {
  visibility: hidden;
}

.feedback-container .input-black-circle {
  border: 2px solid #585858;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.feedback-container .input-black-circle i {
  background-color: transparent;
  width: .7rem;
  height: .7rem;
  border-radius: 50%;
}

.feedback-container input[type='radio']:checked ~ .input-black-circle i {
  background-color: #585858;
}

.feedback-container p,
.feedback-container label {
  font-weight: 600;
}
/*# sourceMappingURL=Feedback.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.cookie-message-container {
  position: fixed;
  bottom: 2rem;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookie-message-container .cookie-message-container-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1700px;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  color: white;
  background-color: rgba(50, 52, 58, 0.85);
  border: 1px solid white;
}

.cookie-message-container .cookie-message-image {
  height: 3rem;
  width: 3rem;
}

.cookie-message-container .cookie-message-image img {
  width: 100%;
  height: auto;
}

.cookie-message-container .cookie-message-description {
  display: flex;
  align-items: center;
  width: 85%;
}

.cookie-message-container a {
  color: white;
  text-decoration: underline;
}

.cookie-message-container .close-cookie-message {
  font-weight: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookie-message-container .close-cookie-message span {
  display: flex;
  height: 3rem;
  width: 3rem;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid white;
  font-size: 3rem;
}

@media screen and (max-width: 1100px) {
  .cookie-message-container .cookie-message-container-content {
    justify-content: space-between;
    width: 95%;
  }
  .cookie-message-container .cookie-message-description {
    width: 75%;
  }
}

@media screen and (max-width: 580px) {
  .cookie-message-container .cookie-message-description {
    width: 75%;
    font-size: .8rem;
  }
}

@media screen and (max-width: 480px) {
  .cookie-message-container .cookie-message-description {
    width: 65%;
  }
}

@media screen and (max-width: 370px) {
  .cookie-message-container .cookie-message-description {
    width: 55%;
  }
}
/*# sourceMappingURL=CookieMessage.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.site-test-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 4rem;
  left: 0;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  z-index: 95;
}

.bug-test-button,
.question-test-button {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 4px solid #f7b614;
  outline: 0;
  background-color: transparent;
}

.bug-test-button img,
.question-test-button img {
  width: 100%;
  height: auto;
}

.bug-test-button {
  left: 3rem;
  bottom: 1rem;
}

.bug-test-button:hover ~ .tooltip .tooltiptext-bug {
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: ease-in-out .5s;
}

.question-test-button {
  right: 3rem;
  bottom: 1rem;
}

.question-test-button:hover ~ .tooltip .tooltip-questionery {
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: ease-in-out .5s;
}

.tooltip {
  display: inline-block;
  border-bottom: 1px solid white;
  color: white;
  visibility: hidden;
}

.tooltip .tooltiptext-bug,
.tooltip .tooltip-questionery {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  border: 2px solid #f7b614;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 52;
  transition: ease-in-out .5s;
  -webkit-transform: scale(0);
          transform: scale(0);
  background-color: rgba(50, 52, 58, 0.8);
}

.tooltip .tooltiptext-bug {
  left: 9rem;
  bottom: 2rem;
}

.tooltip .tooltip-questionery {
  right: 9rem;
  bottom: 2rem;
}

.tooltip .tooltiptext-bug::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #f7b614 transparent transparent;
}

.tooltip .tooltip-questionery::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #f7b614;
}

.bug-report-container {
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.questionnaire-container {
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.questionnaire-container textarea {
  margin-top: 1rem;
}

.question-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-input {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 1rem;
}
/*# sourceMappingURL=SiteTestDiv.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.de-mango-info-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #32343a;
  border-top: 2px dotted lightGray;
  height: 20rem;
}

.de-mango-info-container .message-box-content p,
.de-mango-info-container .message-box-content label {
  color: #585858;
}

.de-mango-info-container .message-box-content p {
  font-weight: 500;
}

.de-mango-info-container .message-box-content .message-box-body {
  padding: 1rem;
}

.de-mango-info-container .message-box-content textarea {
  width: 100%;
}

.de-mango-info-container .message-box-content input,
.de-mango-info-container .message-box-content select {
  padding: .5rem;
}

.de-mango-info-container .de-mango-info-social-media,
.de-mango-info-container .de-mango-info-steps,
.de-mango-info-container .de-mango-info-terms,
.de-mango-info-container .de-mango-info-bug-report {
  width: 25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.de-mango-info-container .de-mango-info-social-media {
  align-items: center;
  color: white;
}

.de-mango-info-container .de-mango-logo {
  width: 50%;
  height: auto;
}

.de-mango-info-container .social-media-container {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-between;
}

.de-mango-info-container .social-media {
  width: 6vmin;
  height: 6vmin;
}

.de-mango-info-container .de-mango-info-steps,
.de-mango-info-container .de-mango-info-terms {
  align-items: flex-start;
}

.de-mango-info-container .de-mango-terms,
.de-mango-info-container .de-mango-contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.de-mango-info-container .de-mango-terms {
  height: 70%;
  justify-content: space-between;
}

.de-mango-info-container .company-name {
  color: #f7b614;
  font-size: 3vmin;
  font-weight: 400;
  font-family: 'Quicksand', sans-serif;
}

.de-mango-info-container .de-mango-info-title {
  color: #f7b614;
  font-size: 3vmin;
}

.de-mango-info-container .de-mango-info-link {
  color: white;
}

.de-mango-info-container .de-mango-info-bug-report {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.de-mango-info-container .description {
  width: 70%;
  cursor: auto;
}

.de-mango-info-container .yellow-letters {
  color: #f7b614;
  display: inline;
}

.de-mango-info-container .report-bug-button {
  background-color: #f7b614;
  color: #32343a;
  outline: 0;
  border: 5px solid #f7b614;
  border-radius: 3px;
  transition: ease-in-out .1s;
  font-size: 1rem;
}

.de-mango-info-container .report-bug-button:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

@media screen and (min-width: 2000px) and (min-height: 1650px) {
  .de-mango-info-container .social-media {
    width: 3vmin;
    height: 3vmin;
  }
  .de-mango-info-container .de-mango-info-title {
    font-size: 3rem;
  }
  .de-mango-info-container .company-name {
    font-size: 3rem;
  }
}

@media screen and (min-width: 2000px) and (min-height: 1300px) and (max-height: 1650px) {
  .de-mango-info-container .social-media {
    width: 4vmin;
    height: 4vmin;
  }
  .de-mango-info-container .de-mango-info-title {
    font-size: 3rem;
  }
  .de-mango-info-container .company-name {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1450px) {
  .de-mango-info-container .description {
    width: 93%;
  }
}

@media screen and (max-width: 1100px) {
  .de-mango-info-container {
    width: 100%;
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .de-mango-info-container .de-mango-info-social-media,
  .de-mango-info-container .de-mango-info-steps,
  .de-mango-info-container .de-mango-info-terms,
  .de-mango-info-container .de-mango-info-bug-report {
    width: 50%;
    height: 20rem;
  }
  .de-mango-info-container .de-mango-info-social-media,
  .de-mango-info-container .de-mango-info-terms {
    padding-left: 1rem;
  }
  .de-mango-info-container .de-mango-logo {
    width: 80%;
  }
  .de-mango-info-container .social-media-container {
    flex-direction: row;
    width: 90%;
    justify-content: space-evenly;
  }
  .de-mango-info-container .social-media {
    width: 5rem;
    height: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .de-mango-info-container .de-mango-info-title {
    font-size: 4vmin;
  }
  .de-mango-info-container .description {
    width: 90%;
  }
  .de-mango-info-container .company-name {
    font: 4vmin;
  }
}

@media screen and (max-width: 550px) {
  .de-mango-info-container {
    flex-direction: column-reverse;
  }
  .de-mango-info-container .de-mango-info-social-media,
  .de-mango-info-container .de-mango-info-steps,
  .de-mango-info-container .de-mango-info-terms,
  .de-mango-info-container .de-mango-info-bug-report {
    width: 100%;
    height: 20rem;
    align-items: center;
  }
  .de-mango-info-container .de-mango-terms,
  .de-mango-info-container .de-mango-contact {
    align-items: center;
  }
  .de-mango-info-container .de-mango-info-title {
    font-size: 6vmin;
  }
  .de-mango-info-container .company-name {
    font-size: 5vmin;
  }
}

@media screen and (max-width: 430px) {
  .de-mango-info-container {
    flex-direction: column-reverse;
  }
  .de-mango-info-container .de-mango-info-steps,
  .de-mango-info-container .de-mango-info-terms,
  .de-mango-info-container .de-mango-info-bug-report {
    align-items: flex-start;
    padding-left: 1rem;
  }
  .de-mango-info-container .de-mango-terms,
  .de-mango-info-container .de-mango-contact {
    align-items: flex-start;
  }
}

@media screen and (max-width: 375px) {
  .de-mango-info-container .message-box-content textarea {
    width: 100%;
  }
  .de-mango-info-container .message-box-content input,
  .de-mango-info-container .message-box-content select {
    width: 100%;
  }
}
/*# sourceMappingURL=DeMangoInfo.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

body {
  background-color: white;
}

.kolorado-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.kolorado-page-container .price-container-big {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.kolorado-page-container .price-container-big img {
  width: 100%;
}

.kolorado-page-container .price-container-small {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: none;
}

.kolorado-page-container .price-container-small img {
  width: 100%;
  height: 100%;
}

.kolorado-page-container .kolorado-delivery-types {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 75%;
  max-width: 2480px;
}

.kolorado-page-container .kolorado-delivery-types .single-delivery-type {
  display: flex;
  color: #585858;
  align-items: center;
  width: 33%;
  margin: auto;
  margin-bottom: 3vmin;
}

.kolorado-page-container .kolorado-delivery-types img {
  width: 7vmin;
  height: 7vmin;
  margin-right: 2vmin;
}

.kolorado-page-container .kolorado-delivery-types p {
  font-size: 2vmin;
}

.kolorado-page-container .kolorado-mango-div {
  display: flex;
  flex-direction: row;
  width: 75%;
  max-width: 2480px;
  height: 300px;
  justify-content: space-between;
  align-items: center;
  color: #585858;
  padding-bottom: 1rem;
}

.kolorado-page-container .kolorado-mango-div .single-mango {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kolorado-page-container .kolorado-mango-div img {
  width: 20vmin;
  height: 20vmin;
}

.kolorado-page-container .kolorado-mango-div p {
  font-size: 1.7vmin;
}

.kolorado-page-container .social-media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 10vmin;
  width: 30vmin;
}

.kolorado-page-container .social-media-container img {
  width: 4vmin;
  height: 4vmin;
}

.kolorado-page-container .social-media-container p {
  color: #9F0808;
  font-size: 3vmin;
}

.kolorado-page-container .social-media-container .social-media-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
}

.kolorado-page-container .main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: white;
  margin-bottom: 6vmin;
  height: 500px;
}

.kolorado-page-container .main-content .phone-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60%;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.kolorado-page-container .main-content .phone-div .first-div,
.kolorado-page-container .main-content .phone-div .third-div {
  background-color: #f7b614;
  width: 50vmin;
  height: 15vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #32343a;
  border: 8px solid lightgray;
  box-shadow: 4px 6px 5px 0px gray;
}

.kolorado-page-container .main-content .phone-div .first-div {
  margin-right: -5%;
  border-radius: 25px 0px 0px 25px;
}

.kolorado-page-container .main-content .phone-div .third-div {
  margin-left: -5%;
  border-radius: 0px 25px 25px 0px;
}

.kolorado-page-container .main-content .phone-div .third-div .hidden-third-div-element {
  display: none;
}

.kolorado-page-container .main-content .phone-div .third-div img {
  width: 30px;
  max-width: 30px;
  height: auto;
}

.kolorado-page-container .main-content .phone-div .middle-div {
  background-color: #cfd8e7;
  width: 28vmin;
  height: 28vmin;
  border-radius: 200px;
  z-index: 2;
  border: 8px solid lightgray;
  transition: all .4s;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kolorado-page-container .main-content .phone-div .middle-div img {
  width: auto;
  height: 100%;
}

.kolorado-page-container .main-content .phone-div .de-mango-bottom {
  width: 40%;
  max-width: 2300px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
}

.kolorado-page-container .main-content .phone-div .de-mango-bottom p {
  color: #585858;
  margin-top: 2%;
}

.kolorado-page-container .main-content .phone-div .de-mango-top {
  height: 10%;
  display: flex;
  align-items: center;
  color: #f7b614;
}

.kolorado-page-container .main-content .phone-div .de-mango-top h1 span {
  color: #585858;
}

.kolorado-page-container .main-content .phone-div .de-mango-bottom {
  height: 20%;
  max-height: 20%;
}

@media screen and (min-width: 1800px) {
  .kolorado-page-container .kolorado-delivery-types {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .kolorado-page-container .kolorado-mango-div {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .kolorado-page-container .social-media-container {
    margin-top: 1rem;
  }
  .kolorado-page-container .main-content .de-mango-bottom p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1400px) {
  .kolorado-page-container .kolorado-delivery-types {
    width: 90%;
  }
  .kolorado-page-container .kolorado-delivery-types p {
    font-size: 1rem;
  }
  .kolorado-page-container .kolorado-mango-div {
    width: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .kolorado-page-container .price-container-big {
    display: none;
  }
  .kolorado-page-container .price-container-small {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1001px) {
  .kolorado-page-container .main-content .de-mango-bottom {
    width: 70%;
    text-align: center;
  }
  .kolorado-page-container .main-content .de-mango-bottom p {
    color: #585858;
  }
  .kolorado-page-container .main-content .phone-div .first-div,
  .kolorado-page-container .main-content .phone-div .third-div {
    width: 22rem;
    height: 7.5rem;
  }
  .kolorado-page-container .main-content .phone-div .middle-div {
    width: 14rem;
    height: 14rem;
  }
}

@media screen and (max-width: 1000px) {
  .kolorado-page-container .kolorado-delivery-types {
    width: 90%;
  }
  .kolorado-page-container .kolorado-mango-div {
    width: 90%;
  }
  .kolorado-page-container .social-media-container {
    margin-bottom: 2rem;
  }
  .kolorado-page-container .main-content .third-div {
    padding-left: 35px;
  }
  .kolorado-page-container .main-content .de-mango-bottom {
    width: 70%;
    text-align: center;
  }
  .kolorado-page-container .main-content .de-mango-bottom p {
    color: #585858;
  }
  .kolorado-page-container .main-content .phone-div .third-div,
  .kolorado-page-container .main-content .phone-div .first-div {
    font-size: 14px;
    width: 17.5rem;
  }
  .kolorado-page-container .main-content .phone-div .third-div img {
    width: 25px;
    max-width: 25px;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  .kolorado-page-container .main-content .phone-div .third-div,
  .kolorado-page-container .main-content .phone-div .first-div {
    width: 17.5rem;
  }
  .kolorado-page-container .social-media-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .kolorado-page-container .social-media-container img {
    width: 8vmin;
    height: 8vmin;
    margin: auto;
  }
  .kolorado-page-container .social-media-container p {
    font-size: 7vmin;
    margin-bottom: 1rem;
  }
  .kolorado-page-container .social-media-container .social-media-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 830px) {
  .kolorado-page-container .kolorado-delivery-types .single-delivery-type {
    width: 40%;
    text-align: left;
  }
  .kolorado-page-container .main-content {
    height: 400px;
    margin-bottom: 0;
  }
  .kolorado-page-container .main-content .phone-div .third-div,
  .kolorado-page-container .main-content .phone-div .first-div {
    width: 35vmin;
  }
}

@media screen and (max-width: 750px) {
  .kolorado-page-container .main-content .phone-div .third-div,
  .kolorado-page-container .main-content .phone-div .first-div {
    font-size: 12px;
  }
  .kolorado-page-container .main-content .phone-div .third-div img {
    width: 23px;
    max-width: 23px;
    height: auto;
  }
}

@media screen and (max-width: 700px) {
  .kolorado-page-container .main-content .phone-div {
    margin-top: 0vmin;
    margin-bottom: 0vmin;
  }
  .kolorado-page-container .main-content .de-mango-bottom {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .kolorado-page-container .main-content .phone-div .third-div,
  .kolorado-page-container .main-content .phone-div .first-div {
    font-size: 10px;
  }
  .kolorado-page-container .main-content .phone-div .third-div {
    padding-left: 20px;
  }
  .kolorado-page-container .main-content .phone-div .third-div img {
    width: 20px;
    max-width: 20px;
    height: auto;
  }
  .kolorado-page-container .kolorado-mango-div {
    height: 200px;
  }
}

@media screen and (max-width: 500px) {
  .kolorado-page-container .kolorado-delivery-types {
    padding-top: 10vmin;
  }
  .kolorado-page-container .kolorado-delivery-types .single-delivery-type {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .kolorado-page-container .kolorado-delivery-types img {
    width: 13vmin;
    height: 13vmin;
    margin-right: 2vmin;
  }
  .kolorado-page-container .kolorado-delivery-types p {
    font-size: 2.3vmin;
  }
  .kolorado-page-container .main-content .phone-div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 3vmin;
  }
  .kolorado-page-container .main-content .phone-div .first-div {
    display: none;
  }
  .kolorado-page-container .main-content .phone-div .middle-div {
    width: 40vmin;
    height: 40vmin;
  }
  .kolorado-page-container .main-content .phone-div .third-div {
    width: 50vmin;
    height: 30vmin;
    align-items: flex-start;
    margin-left: -10vmin;
    padding-left: 45px;
  }
  .kolorado-page-container .main-content .phone-div .third-div .hidden-third-div-element {
    display: inherit;
  }
}

@media screen and (max-width: 455px) {
  .kolorado-page-container .kolorado-mango-div {
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 10vmin;
    padding-bottom: 10vmin;
  }
  .kolorado-page-container .kolorado-mango-div .single-mango {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
  .kolorado-page-container .kolorado-mango-div img {
    width: 35vmin;
    height: 35vmin;
  }
  .kolorado-page-container .kolorado-mango-div p {
    font-size: 3.3vmin;
    text-align: center;
  }
  .kolorado-page-container .main-content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .kolorado-page-container .main-content .de-mango-bottom p {
    font-size: 13px;
  }
  .kolorado-page-container .main-content .phone-div .third-div {
    font-size: 10px;
    padding-left: 60px;
  }
}

@media screen and (max-width: 400px) {
  .kolorado-page-container .main-content .de-mango-top {
    font-size: 14px;
  }
  .kolorado-page-container .main-content .phone-div .third-div {
    padding-left: 44px;
  }
}

@media screen and (max-width: 380px) {
  .kolorado-page-container .main-content .phone-div .third-div {
    font-size: 9px;
    padding-left: 40px;
    width: 60vmin;
  }
  .kolorado-page-container .main-content .phone-div .third-div img {
    width: 20px;
  }
}

@media screen and (max-width: 320px) {
  .kolorado-page-container .main-content .de-mango-top {
    font-size: 12px;
  }
}
/*# sourceMappingURL=KoloradoPage.css.map */
/* .app-container {
    height: 100vh;
    overflow: hidden;
} */
.app-container {
  background-color: white;
  position: relative;
  max-width: 2580px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app-container::-webkit-scrollbar {
  display: none;
}

.app-container .app-content {
  height: 100%;
  background-color: white;
  width: 100%;
}

.app-container .partners {
  width: 100%;
  height: 100%;
}

.app-container .store-stand-fixed {
  width: 100%;
  height: 100%;
}

.app-container .store-stand-fixed nav {
  position: fixed;
}

@media screen and (min-width: 1900px) {
  .app-container .app-content {
    overflow: hidden;
  }
}

@media screen and (min-width: 670px) {
  .app-container .partners {
    overflow: hidden;
  }
}

@media screen and (max-width: 1100px) {
  .app-container .store-stand-fixed nav {
    position: fixed;
  }
  .app-container .store-stand-fixed .store-stand-container {
    margin-top: 5rem;
  }
}
/*# sourceMappingURL=App.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.login-page-testing-info {
  position: relative;
  color: #fff;
  background-color: #fd3333;
  text-align: center;
  padding: 3px 0;
  font-size: 13px;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.login-page-testing-info .blink-text {
  width: 500px;
  margin: 0 5px 0 0;
  border-radius: 24px;
  background: #fff;
  color: #fd3333;
  -webkit-animation: blink-text 1s infinite;
          animation: blink-text 1s infinite;
}

@-webkit-keyframes blink-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login-page-testing-info .blink-text p {
  margin: 0;
}

.login-page-testing-info .minutes-dropdown {
  border: solid #fff 1px;
  border-radius: 24px;
  background: #fd3333;
  margin: 0 0 0 5px;
  color: #fff;
}
/*# sourceMappingURL=app-connection-state.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.order-tracker-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 90%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 98;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
}

.order-tracker-container body::-webkit-scrollbar {
  display: none;
}

.order-tracker-container body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.order-tracker-container .order-tracker-content-box {
  overflow-y: scroll;
}

.order-tracker-container .track-order-content {
  width: 20rem;
  height: 12rem;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
}

.order-tracker-container .order-tracker-head {
  color: #f7b614;
  width: 100%;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
}

.order-tracker-container .order-tracker-head i {
  cursor: pointer;
  margin-right: 1rem;
  font-size: 2rem;
  color: white;
}

.order-tracker-container .order-tracker-head i:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.order-tracker-container .track-order-body {
  color: white;
  padding-top: .5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.order-tracker-container .track-order-body img {
  margin-top: .5rem;
  width: 3.5rem;
}

.order-tracker-container .order-feedback {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 2%;
  background-color: white;
  border-color: #f7b614;
  outline: none;
  border-radius: .3rem;
  color: #32343a;
  font-weight: 550;
}

.order-tracker-container .clear-all {
  width: 10rem;
  padding: .4rem;
  font-weight: 600;
  border-color: #f7b614;
  border-radius: .5rem;
  margin-top: .5rem;
}
/*# sourceMappingURL=OrderTracker.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.go-up-button-container {
  position: fixed;
  bottom: 1rem;
  right: 3rem;
  z-index: 90;
}

.go-up-button-container .go-up-button {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 4px solid #f7b614;
  outline: 0;
  background-color: transparent;
}

.go-up-button-container .go-up-button img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 400px) {
  .go-up-button-container {
    right: 2.5rem;
  }
  .go-up-button-container .go-up-button {
    width: 3rem;
    height: 3rem;
  }
}
/*# sourceMappingURL=GoUpButton.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.contact-us-container {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.contact-us-container .contact-us-content {
  position: relative;
  width: 900px;
  border-radius: 1rem;
  overflow: hidden;
}

.contact-us-container .contact-us-content img {
  width: 100%;
}

.contact-us-container .contact-us-content button {
  position: absolute;
  top: 1rem;
  right: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
  font-size: 1.7rem;
  transition: ease-in-out .2s;
  font-weight: 600;
}

.contact-us-container .contact-us-content button:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

@media screen and (max-width: 900px) {
  .contact-us-container .contact-us-content {
    width: 100%;
  }
  .contact-us-container .contact-us-content button {
    right: 3vmin;
    font-size: 7vmin;
  }
}
/*# sourceMappingURL=ContactUs.css.map */
.black-transparency {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-radius {
  border-radius: 20px;
}

.disabled {
  opacity: 0.3;
}

.no-data-container {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.scrollbar {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.scrollbar ::-webkit-scrollbar {
  width: 5px;
}

.scrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf;
  border-radius: 10px;
}

.scrollbar ::-webkit-scrollbar-thumb {
  background: #c2bdbd;
  border-radius: 20px;
}

.scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #7c7b7b;
}

.terms-container {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.terms-container .terms-content {
  width: 1200px;
  height: 700px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 1rem;
}

.terms-container .aside-footer-questions {
  width: 25%;
  height: 95%;
  border-right: 1px solid #808080;
}

.terms-container .aside-footer-answers {
  width: 75%;
  height: 100%;
  padding: 1rem;
  background-image: url(../../static/media/mango_gray.ddacd236.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.terms-container .answer-body {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.terms-container .answer-body p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #32343a;
}

.terms-container .answer-body h2,
.terms-container .answer-body h3 {
  text-align: center;
  color: #f7b614;
}

.terms-container .answer-body h5 {
  color: #585858;
  font-size: 2rem;
  text-align: center;
  margin-top: .5rem;
}

.terms-container .answer-body li {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: .8rem;
  margin-top: .8rem;
}

.terms-container .answer-body li a {
  font-size: 2rem;
}

.terms-container .answer-body a {
  color: #f7b614;
  font-weight: bold;
}

.terms-container .neki {
  width: 100%;
  height: 100%;
}

.terms-container .site-help,
.terms-container .customer-service {
  width: 100%;
  height: 50%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.terms-container .de-mango-terms-title {
  font-size: 1.5rem;
  color: #f7b614;
}

.terms-container .terms-info-link {
  color: #32343a;
  cursor: pointer;
}

.terms-container .selected {
  color: #f7b614;
}

.terms-container .answer-header {
  width: 100%;
  height: 5%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.terms-container .answer-header button {
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 700;
  font-size: 1.8rem;
  transition: ease-in-out .2s;
}

.terms-container .answer-header button:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

@media screen and (max-height: 700px) {
  .terms-container .terms-content {
    height: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .terms-container .terms-content {
    width: 1000px;
  }
}

@media screen and (max-width: 1000px) {
  .terms-container .terms-content {
    width: 800px;
  }
  .terms-container .answer-body li {
    font-size: 3vmin;
    margin-bottom: .5rem;
    margin-top: .5rem;
    font-weight: 400;
  }
  .terms-container .answer-body li a {
    font-size: 3vmin;
  }
  .terms-container .answer-body h5 {
    font-size: 4vmin;
  }
}

@media screen and (max-width: 800px) {
  .terms-container .terms-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .terms-container .aside-footer-answers {
    background-size: 100%;
  }
}

@media screen and (max-width: 600px) {
  .terms-container .aside-footer-answers p,
  .terms-container .aside-footer-questions p {
    font-size: 2.5vmin;
    font-weight: 500;
  }
  .terms-container .aside-footer-questions {
    text-align: center;
  }
}
/*# sourceMappingURL=TermsConditions.css.map */
